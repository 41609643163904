import React from 'react';
import axios from 'axios';
import { Typography, Stack, Fab } from '@mui/material';
import { API_URL } from '../../utils/constants/paths';
import FolderZipIcon from '@mui/icons-material/FolderZip';

import LinearProgress from '@mui/material/LinearProgress';
import Div from "@jumbo/shared/Div";

const FichaGeneralPdf = () => {
    const [progressVisible, setProgressVisible] = React.useState(false);
    const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
    const [openOkMessage, setOpenOkMessage] = React.useState(false);
    const handlePdf = () => {
        setOpenErrorMessage(false);
        setProgressVisible(true);
        setOpenOkMessage(false);
        axios.post(`${API_URL}evaluacion/pdfReportAll`, {}, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } }).then((response) => {
            setOpenErrorMessage(false);
            setProgressVisible(false);
            setOpenOkMessage(true);
        }).catch((error) => {
            setOpenErrorMessage(true);
            setProgressVisible(false);
            setOpenOkMessage(false);
        });
    }

    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Fichas del arbolado</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { handlePdf(); }} color={'primary'}><FolderZipIcon /></Fab>}
            </Stack>
            {progressVisible &&
                <Div sx={{ width: '100%' }}>
                    <LinearProgress />
                </Div>
            }
            {openErrorMessage &&
                <Div sx={{ width: '100%' }}>
                    Ocurrió un error, intente nuevamente.
                </Div>
            }
            {openOkMessage &&
                <Div sx={{ width: '100%' }}>
                    El archivo zip con todas las fichas del arbolado se creó correctamente en la ubicación establecida por el administrador.
                </Div>
            }
        </React.Fragment>
    );
};
export default FichaGeneralPdf;
