import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import PictureItem2 from "./PictureItem2";
//import {userPhotos} from "./data";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useTranslation } from "react-i18next";
import ImageList from "@mui/material/ImageList";
import Div from "@jumbo/shared/Div";

const UserPhotos = ({ data, setRefresh, setFotoArbolPatrimonialId, setOpenFotoUnica, setFotoItem, openFotoUnica, setDisabledFotos }) => {
    const { t } = useTranslation();
    return (
        <JumboCardQuick
            title={t("widgets.title.pictures")}
            wrapperSx={{ p: 0 }}
        >
            <JumboScrollbar autoHeight autoHideTimeout={200} autoHeightMin={300} autoHide>
                <Div sx={{ px: 3 }}>
                    <ImageList cols={5} gap={16} sx={{ width: '100%', height: 'auto', my: 0 }}>
                        {
                            Array.isArray(data)
                                ?
                                data.map((item, index) => (
                                    <PictureItem2 item={item} key={index} setRefresh={setRefresh} setFotoArbolPatrimonialId={setFotoArbolPatrimonialId} setOpenFotoUnica={setOpenFotoUnica} setFotoItem={setFotoItem} openFotoUnica={openFotoUnica} setDisabledFotos={setDisabledFotos} />
                                ))
                                : null
                        }
                    </ImageList>
                </Div>
            </JumboScrollbar>
        </JumboCardQuick>
    );
};

export default UserPhotos;
