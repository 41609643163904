import React from 'react';
import Typography from "@mui/material/Typography";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { useTranslation } from "react-i18next";
import code from './demo-code/marker-cluster.txt';
import axios from "axios";
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import { ASSET_IMAGES } from "../../utils/constants/paths";

import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Accordion, AccordionDetails, Card } from "@mui/material";
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';

const MarkClustererExampleMap = () => {
    const [markers, setMarkers] = React.useState([]);
    const [center, setCenter] = React.useState({ lat: 0.1, lng: -78.0 });
    const [expanded, setExpanded] = React.useState(false);
    const [progressVisible, setProgressVisible] = React.useState(false);
    const [selectedMarker, setSelectedMarker] = React.useState([]);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD8F4WjzM6MRgfsye95HQ-WDj3qeWU-lFc"

    });

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const handleClick = () => {
        setExpanded(!expanded);
    }
    React.useEffect(() => {
        const abortController = new AbortController();
        setProgressVisible(true);
        axios.post(`${API_URL}arbolPatrimonial/getPaginated`, { pageNumber: 1, rowsOfPage: 99999 }, { headers }).then((response) => {
            setMarkers(response.data);
            setProgressVisible(false);
        }).catch((err) => {
            setProgressVisible(false);
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        return () => {
            abortController.abort();
        }
    }, []);

    return (
        <React.Fragment>
            {
                isLoaded &&
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: "450px" }}
                    center={center}
                    zoom={9}
                >
                    <MarkerClusterer
                        enableRetinaIcons
                        averageCenter
                        gridSize={60}
                    >
                        {(clusterer) =>
                            markers.map(marker => (
                                <Marker
                                    onClick={(e) => {
                                        setSelectedMarker(marker);
                                        setCenter({ lat: marker.latitud, lng: marker.longitud });
                                    }}
                                    icon={`${ASSET_IMAGES}/marker.png`}
                                    position={{ lat: marker.latitud, lng: marker.longitud }}
                                    key={marker.arbolPatrimonialId}
                                    clusterer={clusterer}>
                                </Marker>
                            ))
                        }
                    </MarkerClusterer>
                </GoogleMap>
            }
            <Card sx={{ mb: 1 }}>
                {selectedMarker && selectedMarker.arbolPatrimonialId && <Accordion expanded={expanded} onChange={handleClick} square sx={{ borderRadius: 2 }}>
                    <AccordionSummary

                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                    margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Div sx={{ flexShrink: 0, px: 1 }}>
                            <ParkOutlinedIcon></ParkOutlinedIcon>
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '7%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            <Typography variant={"h5"} mb={.5} fontSize={14}>{selectedMarker.codigo}</Typography>
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '6%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            {selectedMarker.estaVivo && <Chip color={"success"} size={"small"} label="VIVO" />}
                            {!selectedMarker.estaVivo && <Chip color={"secondary"} size={"small"} label="MUERTO" />}
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '25%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            <Typography variant={"h5"} mb={.5} fontSize={14}>{selectedMarker.especieNombre}</Typography>
                            <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>

                                {selectedMarker.nombreComun}
                            </Typography>
                        </Div>
                        <Div sx={{ display: { xs: 'none', lg: 'block' }, width: '31%', flexShrink: 0, px: 1 }}>

                            <Typography variant={"body1"}>{selectedMarker.sectorNombre}</Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                {selectedMarker.ubicacion}
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                {selectedMarker.referencia}
                            </Typography>
                        </Div>
                        <Div sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                Latitud
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                {selectedMarker.latitud}
                            </Typography>

                        </Div>
                        <Div sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                Longitud
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                {selectedMarker.longitud}
                            </Typography>
                        </Div>

                    </AccordionSummary>

                </Accordion>}


            </Card>
            {progressVisible &&
                <Div sx={{ width: '100%' }}>
                    <LinearProgress />
                </Div>
            }
        </React.Fragment>
    )

}
const MarkerClustererExample = ({ isExample }) => {
    const { t } = useTranslation();
    if (!isExample)
        return (
            <JumboCardQuick noWrapper>
                <MarkClustererExampleMap />
            </JumboCardQuick>
        );
    return (
        <React.Fragment>
            <Typography variant={"h1"} mb={3}>{t('pages.title.mapMarkerCluster')}</Typography>
            <JumboDemoCard demoCode={code} wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}>
                <MarkClustererExampleMap />
            </JumboDemoCard>
        </React.Fragment>
    );
};

MarkerClustererExample.defaultProps = {
    isExample: false
};

export default MarkerClustererExample;
