import React from "react";
import { Navigate } from "react-router-dom";
import intervencionesRoutes from "../routes/interevencionesRoutes";
import catalogosRoutes from "../routes/catalogosRoutes";
import Page from "@jumbo/shared/Page";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";


/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/catalogos/ArbolPatrimonial"} />
    },
    ...intervencionesRoutes,
    ...catalogosRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/user/login",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true} />
    },
    {
        path: "/user/resetPassword",
        element: <Page component={Signup2} layout={"solo-page"} disableSmLogin={true} />
    }
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
