import React from "react";
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import MapIcon from '@mui/icons-material/Map';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import PlaceIcon from '@mui/icons-material/Place';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import BuildIcon from '@mui/icons-material/Build';
import LightModeIcon from '@mui/icons-material/LightMode';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DescriptionIcon from '@mui/icons-material/Description';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const menus = [
    {
        label: 'sidebar.menu.visualizacion',
        type: "section",
        children: [
            {
                uri: "/catalogos/Mapa",
                label: 'sidebar.menuItem.mapa',
                type: "nav-item",
                icon: <MapIcon sx={{ fontSize: 20 }} />
            },
            /* {
                uri: "/catalogos/Galeria",
                label: 'sidebar.menuItem.galeria',
                type: "nav-item",
                icon: <CollectionsIcon sx={{ fontSize: 20 }} />
            }, */
        ]
    },
    {
        label: 'sidebar.menu.gestion',
        type: "section",
        children: [
            {
                uri: "/catalogos/ArbolPatrimonial",
                label: 'sidebar.menuItem.arboles',
                type: "nav-item",
                icon: <ParkOutlinedIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'sidebar.menu.reportes',
        type: "section",
        children: [
            {
                uri: "/catalogos/ReporteGeneral",
                label: 'sidebar.menuItem.reporteGeneral',
                type: "nav-item",
                icon: <AssessmentIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/catalogos/FichaGeneralPdf",
                label: 'sidebar.menuItem.fichaGeneralPdf',
                type: "nav-item",
                icon: <FolderZipIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'sidebar.menu.seguridad',
        type: "section",
        children: [
            {
                uri: "/catalogos/Usuario",
                label: 'sidebar.menuItem.usuarios',
                type: "nav-item",
                icon: <GroupIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/catalogos/UsuarioPerfil",
                label: 'sidebar.menuItem.perfiles',
                type: "nav-item",
                icon: <AssignmentIndIcon sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'sidebar.menu.catalogos',
        type: "section",
        children: [
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.general',
                type: "collapsible",
                icon: <FormatAlignJustifyIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/Especie",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.especie',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/Sector",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.sector',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/Forma",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.forma',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/Posicion",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.posicion',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/UltimaPoda",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.ultimaPoda',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/ValorEstetico",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.valorEstetico',
                        type: "nav-item",
                    }
                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.lugar',
                type: "collapsible",
                icon: <PlaceIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/TipologiaLugar",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.tipologiaLugar',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/FertilidadSuelo",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.fertilidadSuelo',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/Suelo",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.suelo',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/Irrigacion",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.irrigacion',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/dificultadOperativa",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.dificultadOperativa',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/dificultadOperativaDescripcion",
                        label: "Motivo dificultad intervención",
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/RaizBajoPavimento",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.raizBajoPavimento',
                        type: "nav-item",
                    }
                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.fitopatologia',
                type: "collapsible",
                icon: <EnhancedEncryptionIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/ColorFollaje",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.colorFollaje',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/DensidadCopa",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.densidadCopa',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/DimensionHojas",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.dimensionHojas',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/CrecimientoAnual",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.crecimientoAnual',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/CrecimientoCallo",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.crecimientoCallo',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/BrotesEpicormicos",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.brotesEpicormicos',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/RamasSecas",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.ramasSecas',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/DanosCopa",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.danosCopa',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/DanoRadicular",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.danoRadicular',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AgenteDano",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.agenteDano',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/PatologiaIdentificacion",
                        label: "Identificación del patógeno",
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/PatologiaNivelDano",
                        label: "Nivel daño patógneo",
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/CausaPatologiaIdentificacion",
                        label: "Identificación de agente de daño",
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/CausaPatologiaNivelAgenteDano",
                        label: "Nivel agente de daño",
                        type: "nav-item",
                    },

                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.mecanica',
                type: "collapsible",
                icon: <BuildIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/Inclinacion",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.inclinacion',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/ReduccionAparatoRadicular",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.reduccionAparatoRadicular',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AlteracionesRadiculares",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.alteracionesRadiculares',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AlteracionesCuelloRaiz",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.alteracionesCuelloRaiz',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AlteracionesTronco",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.alteracionesTronco',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AlteracionesInsercionRamaPrincipal",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.alteracionesInsercionRamaPrincipal',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/AlteracionesRamasSecundarias",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.alteracionesRamasSecundarias',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/CuerposFructiferos",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.cuerposFructiferos',
                        type: "nav-item",
                    },
                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.riesgo',
                type: "collapsible",
                icon: <ReportProblemIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/RiesgoCaida",
                        label: "Riesgo de caida",
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/SusceptibilidadArea",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.susceptibilidadArea',
                        type: "nav-item"
                    },
                    {
                        uri: "/catalogos/ConcurrenciaArea",
                        label: "Concurrencia del área",
                        type: "nav-item"
                    }
                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.planManejo',
                type: "collapsible",
                icon: <DescriptionIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/PlanManejo",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.planManejo',
                        type: "nav-item"
                    },
                ]
            },
            {
                label: 'sidebar.menuItem.heritageTrees.catalogos.sections.aspectosPatrimonialesSenaletica',
                type: "collapsible",
                icon: <AcUnitIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/CaracteristicasPatrimoniales",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.caracteristicasPatrimoniales',
                        type: "nav-item",
                    },
                    {
                        uri: "/catalogos/EstadoLetrero",
                        label: 'sidebar.menuItem.heritageTrees.catalogos.estadoLetrero',
                        type: "nav-item",
                    }
                ]
            },
            {
                label: "ECOLOGÍA",
                type: "collapsible",
                icon: <ReportProblemIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/catalogos/ValorEcologico",
                        label: "Valor ecológico",
                        type: "nav-item",
                    }
                ]
            },
        ]
    }
];

export default menus;
