import React from 'react';
import axios from 'axios';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import PictureItem3 from "./PictureItem3";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useTranslation } from "react-i18next";
import ImageList from "@mui/material/ImageList";
import Div from "@jumbo/shared/Div";
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import { useNavigate } from "react-router-dom";
import ImageListItem from "@mui/material/ImageListItem";
import { ASSET_IMAGES } from "../../../app/utils/constants/paths";



const Galeria = ({ }) => {
    const { t } = useTranslation();
    const [data, setData] = React.useState([]);
    const [openFotoUnica, setOpenFotoUnica] = React.useState(false);
    const [fotoPath, setFotoPath] = React.useState(false);
    
    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    React.useEffect(() => {
        axios.post(`${API_URL}foto/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setData(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, []);


    return (
        <Div>
            <JumboCardQuick
                title={t("widgets.title.pictures")}
                wrapperSx={{ p: 0 }}
            >
                <JumboScrollbar autoHeight autoHideTimeout={200} autoHeightMin={450} autoHide >
                    <Div sx={{ px: 3 }}>
                        <ImageList cols={5} gap={16} sx={{ width: '100%', height: 'auto', my: 0 }}>
                            {
                                data.map((item, index) => (
                                    <PictureItem3 item={item} key={index} setOpenFotoUnica={setOpenFotoUnica} setFotoPath={setFotoPath} openFotoUnica={openFotoUnica} />
                                ))
                            }
                        </ImageList>
                    </Div>
                </JumboScrollbar>
            </JumboCardQuick>
            <Dialog fullWidth='true' maxWidth='xl' open={openFotoUnica} onClose={() => setOpenFotoUnica(false)}>
                <DialogContent>
                    <Div>
                        <ImageListItem
                            key={fotoPath}
                            sx={{
                                borderRadius: 2,
                                overflow: 'hidden',

                                '& .MuiImageListItemBar-root': {
                                    transition: 'all 0.3s ease',
                                    transform: 'translateY(100%)',
                                },

                                '&:hover .MuiImageListItemBar-root': {
                                    transform: 'translateY(0)',
                                }
                            }}
                        >
                            <img
                                src={`${ASSET_IMAGES}/fotografias/${fotoPath}`}
                                alt="image"
                                loading="eager"
                            />
                        </ImageListItem>
                    </Div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenFotoUnica(false); }}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Div>
    );
};

export default Galeria;
