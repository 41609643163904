import React from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {API_URL,ROWS_OF_PAGE} from '../../utils/constants/paths';
import {Card,ListItemIcon, ListItemButton, Fab,Grid} from '@mui/material';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const EvaluacionAlteracionesRadicularesItem = ({item,setItems, currentPage, setTotalPages, commandAlert, setOpen, setRefItem, setCreateMode }) => {
    const [deleteItem, setDeleteItem] = React.useState(false);
    const Swal = useSwalWrapper();
    const confirmDelete = (keyItem) => { Swal.fire({ title: `¿Está seguro de eliminar: ${keyItem}?`, text: 'No se podrá revertir esta acción!', icon: 'warning', showCancelButton: true, confirmButtonText: 'Si, eliminar!', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => {if (result.value) {setDeleteItem(true);} else if (result.dismiss === Swal.DismissReason.cancel) {}});};
    
    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    React.useEffect(() => {
        if (!deleteItem)
            return;
        let toDeleteItem = item;
        toDeleteItem.rowsOfPage = ROWS_OF_PAGE;
        toDeleteItem.pageNumber = currentPage;
        axios.post(`${API_URL}evaluacionAlteracionesRadiculares/delete`, toDeleteItem, {headers}).then((response) => {
            setItems(response.data);
            setTotalPages(response.data[0].totalPages);
            commandAlert('success','eliminó',null);
            setDeleteItem(false);
        }).catch((error) => {
            if (error.response) {
                commandAlert('error','',error.response.data);
            }   
            setDeleteItem(false);
        });
    }, [deleteItem]);

    return (
        <Card sx={{ mb: 0.5 }}>
            <ListItemButton component={'li'} sx={{p: theme => theme.spacing(1, 3), '&:hover .ListItemIcons': { opacity: 1}}}>
                <Grid container alignItems='center' justifyContent='center' spacing={3.75} sx={{ p: theme => theme.spacing(0.8, 1) }} >
                    <Grid item xs={3} md={3} lg={3}>
                        {item.evaluacionEvaluacionId}
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        {item.alteracionesRadicularesNombre}
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        {item.nota}
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        {item.fechaModificacion}
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                        {item.nombreCortoUsuario}
                    </Grid>
                </Grid>
                <ListItemIcon className={'ListItemIcons'} sx={{position: 'absolute', right: 24, top: 7, transition: 'all 0.2s', opacity: 0 }}>
                    <Fab onClick={() => { setOpen(true); setRefItem(item); setCreateMode(false); }} size='small' color={'primary'} sx={{ right: 14 }}><EditIcon /></Fab>
                    <Fab onClick={() => confirmDelete(item.evaluacionAlteracionesRadicularesId)} size='small' color={'secondary'}><DeleteIcon /></Fab>
                </ListItemIcon>
            </ListItemButton>
        </Card>
    );
};
export default EvaluacionAlteracionesRadicularesItem;
