import React from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {Accordion, AccordionDetails, Card, Typography} from "@mui/material";
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";

const ProjectItem = ({item}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleClick = () => {
            setExpanded(!expanded);
    }
    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} onChange={handleClick} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{flexShrink: 0, px: 1}}>
                        <ParkOutlinedIcon></ParkOutlinedIcon>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '7%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography variant={"h5"} mb={.5} fontSize={14}>{item.codigo}</Typography>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '22%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography variant={"h5"} mb={.5} fontSize={14}>{item.especieNombre}</Typography>
                        <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>
                            
                            {item.nombreComun}
                        </Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '30%', flexShrink: 0, px: 1}}>
                        
                        <Typography variant={"body1"}>{item.sectorNombre}</Typography>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {item.ubicacion}
                        </Typography>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {item.referencia}
                        </Typography>
                    </Div>
                    <Div sx={{ flex: {xs: '0 1 auto', lg: 1}, flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                            sx={{
                                display: {xs: 'none', lg: 'block'}
                            }}
                        >
                            Latitud
                        </Typography>
                        <Chip color={"primary"} size={"small"} label={item.latitud}/>
                    </Div>
                    <Div sx={{ flex: {xs: '0 1 auto', lg: 1}, flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                            sx={{
                                display: {xs: 'none', lg: 'block'}
                            }}
                        >
                            Longitud
                        </Typography>
                        <Chip color={"secondary"} size={"small"} label={item.longitud}/>
                    </Div>
                    
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 8.25)}}>
                    <Typography variant={"h5"}>Listado de evaluaciones:</Typography>
                    
                    <Typography variant={"body1"} color={"text.secondary"}>
                    <Div>Diametro a la altura del pecho	1</Div>
                    <Div>Ancho de copa					1</Div>
                    <Div>Altura							9</Div>
                    <Div>Altura a la primera rama		4</Div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default ProjectItem;
