import React from 'react';
import Div from "@jumbo/shared/Div";
import { Card, CardContent, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import jwtAuthAxios from "../../../services/auth/jwtAuth";
import { useNavigate } from "react-router-dom";
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import LinearProgress from '@mui/material/LinearProgress';

const Signup2 = () => {
    const formRef = React.useRef();
    const [currentPassword, setCurrentPassword] = React.useState(null);
    const [newPassword1, setNewPassword1] = React.useState(null);
    const [newPassword2, setNewPassword2] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [progressVisible, setProgressVisible] = React.useState(false);

    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const showMessage = () => {
        Swal.fire({ title: `La contraseña se ha cambiado con éxito`, text: 'Ahora será redirigido a la página de ingreso.', icon: 'info', showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => {
            if (result.value) {
                navigate('/user/login');
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    };
    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>

            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.png`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mb: 2 }}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Cambio de contraseña</Typography>
                            <Typography variant={"body1"} mb={2}>
                                Bienvenido al Sistema de Información de Gestión de Árboles Patrimoniales
                            </Typography>
                            <Typography variant={"body1"}>
                                Antes de continuar, debe cambiar su contraseña.
                            </Typography>
                        </Div>

                        {/* <Div sx={{mt: 'auto'}}>
                            <Link href="#" underline="none" sx={{display: 'inline-flex'}}>
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Jumbo React"/>
                            </Link>
                        </Div> */}
                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <form ref={formRef}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                disabled
                                fullWidth
                                id="email"
                                label="Email"
                                defaultValue={sessionStorage.getItem('email')}
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 2 }}>
                            <TextField
                                fullWidth
                                id="passwordOld"
                                label="Contraseña actual"
                                type="password"
                                required
                                value={currentPassword}
                                onChange={(event) => { setCurrentPassword(event.target.value); }}
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 2 }}>
                            <TextField
                                fullWidth
                                id="passwordNew1"
                                label="Contraseña nueva"
                                type="password"
                                required
                                value={newPassword1}
                                onChange={(event) => { setNewPassword1(event.target.value); }}
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 2 }}>
                            <TextField
                                fullWidth
                                id="passwordNew2"
                                label="Confirmación nueva contraseña"
                                type="password"
                                required
                                value={newPassword2}
                                onChange={(event) => { setNewPassword2(event.target.value); }}
                            />
                        </Div>
                    </form>
                    <Button variant="contained" sx={{ mb: 2 }} onClick={() => {
                        if (!formRef.current.reportValidity())
                            return;
                        if (newPassword1 === newPassword2) {
                            setProgressVisible(true);
                            jwtAuthAxios.post('/auth', { email: sessionStorage.getItem('email'), password: currentPassword, passwordNew: newPassword1, isChangePassword: true }).then((response) => {
                                showMessage();
                                setShowErrorMessage(false);
                                setProgressVisible(false);
                            }).catch((error) => {
                                setErrorMessage(error.response.data);
                                setShowErrorMessage(true);
                                setProgressVisible(false);
                            });
                        }
                        else {
                            setErrorMessage("La nueva contraseña y su confirmación no coinciden");
                            setShowErrorMessage(true);
                        }
                    }}>Cambiar contraseña</Button>
                    {progressVisible &&
                        <Div sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Div>
                    }
                    {showErrorMessage &&
                        <Div sx={{ width: '100%' }}>
                            {errorMessage}
                        </Div>
                    }
                </CardContent>
            </Card>
        </Div>
    );
};

export default Signup2;
