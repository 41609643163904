import React from "react";
import Page from "@jumbo/shared/Page";
import Arboles from "app/pages/intervenciones/Arboles";
import Mapa from "app/pages/catalogos/Mapa";
import Intranet from "app/pages/catalogos/Intranet";
const intervencionesRoutes = [
    {
        path: "/intervenciones/Arboles",
        element: <Page component={Arboles} />,
    },
    {
        path: "/catalogos/Mapa",
        element: <Page component={Mapa} />,
    },
    {
        path: "/catalogos/Galeria",
        element: <Page component={Intranet} />,
    },
];

export default intervencionesRoutes;
