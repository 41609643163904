import React from 'react';
import { ImageListItemBar, useTheme, createTheme } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { ASSET_IMAGES } from "../../../app/utils/constants/paths";
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';

const PictureItem3 = ({ item, setOpenFotoUnica, setFotoPath, openFotoUnica }) => {
    const theme = useTheme();
    const theme2 = createTheme({
        typography: {
            subtitle1: {
                fontSize: '0.8rem'
            },
            subtitle2: {
                fontSize: '0.8rem',
                fontWeight: 1000,
            },
            subtitle3: {
                fontSize: '0.72rem'
            }

        },
    });

    const Swal = useSwalWrapper();

    const download = (path) => {
        let extension = path.split('.');
        fetch(`${ASSET_IMAGES}/fotografias/${path}`, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `image.${extension[1]}`);
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }


    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };


    return (
        <ImageListItem
            key={item.img}
            sx={{
                borderRadius: 2,
                overflow: 'hidden',
                minHeight: '120px',

                '& .MuiImageListItemBar-root': {
                    transition: 'all 0.3s ease',
                    transform: 'translateY(100%)',
                },

                '&:hover .MuiImageListItemBar-root': {
                    transform: 'translateY(0)',
                }
            }}
        >

            <img
                src={`${ASSET_IMAGES}/fotografias/${item.path}?w=248&fit=crop&auto=format`}
                alt={item.codigo}
                loading="eager"
            />
            {!openFotoUnica && < ImageListItemBar
                //title={item.codigo}
                subtitle={
                    <Div>
                        <Div>
                            <Typography theme={theme2} variant='subtitle2'>{item.nombreComun}</Typography>
                            <Typography theme={theme2} variant='subtitle2'>{item.codigo}</Typography>
                            <Typography theme={theme2} variant='subtitle1'>{item.fecha ? item.fecha.toString().substring(0, 10) : ""}</Typography>
                            <Typography theme={theme2} variant='subtitle3'>{item.autor}</Typography>
                            <div>&nbsp;</div>
                        </Div>
                        <IconButton aria-label="delete" size="small" sx={
                            {
                                bgcolor: '#00a8ff',
                                color: 'common.white',
                                p: theme => theme.spacing(1.25),

                                '&:hover': {
                                    backgroundColor: '#00a8ff',
                                }
                            }
                        }>
                            <VisibilityIcon fontSize="inherit" onClick={() => { setFotoPath(item.path); setOpenFotoUnica(true); }} />
                        </IconButton>
                        {/* &nbsp;
                        <IconButton aria-label="delete" size="small" sx={
                            {
                                bgcolor: '#00a8ff',
                                color: 'common.white',
                                p: theme => theme.spacing(1.25),

                                '&:hover': {
                                    backgroundColor: '#00a8ff',
                                }
                            }
                        }>
                            <DownloadIcon fontSize="inherit" onClick={() => { download(item.path) }} />
                        </IconButton> */}
                    </Div>
                }
            />}
        </ImageListItem>
    );
};
/* Todo item prop define */
export default PictureItem3;
