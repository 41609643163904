import React from 'react';
import {Grid} from "@mui/material";
import {Galeria} from "../../../app/pages/UserPhotos";

const Intranet = () => {
    return (
        <Grid container spacing={3.75}>
            
            <Grid item xs={12} md={12} lg={12}>
                <Galeria/>
            </Grid>

        </Grid>
    );
};

export default Intranet;
