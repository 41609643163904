import React from 'react';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import { Card, CardHeader, CardContent } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import EvaluacionPlanManejoItem from '../../pages/catalogos/EvaluacionPlanManejoItem';
import { useNavigate } from "react-router-dom";

const EvaluacionCustom = ({ open, setOpen, createMode, refItem, catalogs, arbolItem, indexEvaluacion, setRefresh, disabledEvaluacion }) => {
    const { t } = useTranslation();
    const formRef = React.useRef();

    const [forma, setForma] = React.useState({});
    const [posicion, setPosicion] = React.useState({});
    const [ultimaPoda, setUltimaPoda] = React.useState({});
    const [valorEstetico, setValorEstetico] = React.useState({});
    const [tipologiaLugar, setTipologiaLugar] = React.useState({});
    const [fertilidadSuelo, setFertilidadSuelo] = React.useState({});
    const [suelo, setSuelo] = React.useState({});
    const [irrigacion, setIrrigacion] = React.useState({});
    const [dificultadOperativa, setDificultadOperativa] = React.useState({});
    const [dificultadOperativaDescripcion, setDificultadOperativaDescripcion] = React.useState({});
    const [raizBajoPavimento, setRaizBajoPavimento] = React.useState({});
    const [colorFollaje, setColorFollaje] = React.useState({});
    const [densidadCopa, setDensidadCopa] = React.useState({});
    const [tamanoHoja, setTamanoHoja] = React.useState({});
    const [crecimientoAnual, setCrecimientoAnual] = React.useState({});
    const [crecimientoCallo, setCrecimientoCallo] = React.useState({});
    const [patologiaIdentificacion, setPatologiaIdentificacion] = React.useState({});
    const [patologiaNivelDano, setPatologiaNivelDano] = React.useState({});
    const [causaPatologiaIdentificacion, setCausaPatologiaIdentificacion] = React.useState({});
    const [causaPatologiaNivelAgenteDano, setCausaPatologiaNivelAgenteDano] = React.useState({});
    const [riesgoCaida, setRiesgoCaida] = React.useState({});
    const [susceptibilidadArea, setSusceptibilidadArea] = React.useState({});
    const [concurrenciaArea, setConcurrenciaArea] = React.useState({});
    const [estadoLetrero, setEstadoLetrero] = React.useState({});
    const [valorEcologico, setValorEcologico] = React.useState({});

    const [agenteDano, setAgenteDano] = React.useState({});
    const [parasitosFungicos, setParasitosFungicos] = React.useState({});
    const [inclinacion, setInclinacion] = React.useState({});
    const [reduccionAparatoRadicular, setReduccionAparatoRadicular] = React.useState({});
    const [cavidades, setCavidades] = React.useState({});
    const [cavidadesDimensionMedia, setCavidadesDimensionMedia] = React.useState({});
    const [propensoRuptura, setPropensoRuptura] = React.useState({});

    const [intensidadUsoArea, setIntensidadUsoArea] = React.useState({});
    const [broteEpicormico, setBroteEpicormico] = React.useState({});
    const [ramaSeca, setRamaSeca] = React.useState({});
    const [danoCopa, setDanoCopa] = React.useState({});
    const [danoRadicular, setDanoRadicular] = React.useState({});
    const [alteracionesCuelloRaiz, setAlteracionesCuelloRaiz] = React.useState({});
    const [alteracionesInsercionRamaPrincipal, setAlteracionesInsercionRamaPrincipal] = React.useState({});
    const [alteracionesRadiculares, setAlteracionesRadiculares] = React.useState({});
    const [alteracionesRamasSecundarias, setAlteracionesRamasSecundarias] = React.useState({});
    const [alteracionesTronco, setAlteracionesTronco] = React.useState({});
    const [caracteristicasPatrimoniales, setCaracteristicasPatrimoniales] = React.useState({});
    const [cuerposFructiferos, setCuerposFructiferos] = React.useState({});
    const [planManejo, setPlanManejo] = React.useState({});

    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [arbolPatrimonialId, setArbolPatrimonialId] = React.useState(null);
    const [evaluador, setEvaluador] = React.useState(null);
    const [fecha, setFecha] = React.useState(null);
    const [dap, setDap] = React.useState(null);
    const [circunferencia, setCircunferencia] = React.useState(null);
    const [estadoLetreroObservaciones, setEstadoLetreroObservaciones] = React.useState(null);
    const [proyeccionCopa, setProyeccionCopa] = React.useState(null);
    const [altura, setAltura] = React.useState(null);
    const [alturaPrimeraRama, setAlturaPrimeraRama] = React.useState(null);
    const [troncos, setTroncos] = React.useState(null);
    const [formaId, setFormaId] = React.useState(null);
    const [posicionId, setPosicionId] = React.useState(null);
    const [ultimaPodaId, setUltimaPodaId] = React.useState(null);
    const [valorEsteticoId, setValorEsteticoId] = React.useState(null);
    const [tipologiaLugarId, setTipologiaLugarId] = React.useState(null);
    const [fertilidadSueloId, setFertilidadSueloId] = React.useState(null);
    const [sueloId, setSueloId] = React.useState(null);
    const [irrigacionId, setIrrigacionId] = React.useState(null);
    const [dificultadOperativaId, setDificultadOperativaId] = React.useState(null);
    const [dificultadOperativaDescripcionId, setDificultadOperativaDescripcionId] = React.useState(null);
    const [raizBajoPavimentoId, setRaizBajoPavimentoId] = React.useState(null);
    const [colorFollajeId, setColorFollajeId] = React.useState(null);
    const [densidadCopaId, setDensidadCopaId] = React.useState(null);
    const [tamanoHojaId, setTamanoHojaId] = React.useState(null);
    const [crecimientoAnualId, setCrecimientoAnualId] = React.useState(null);
    const [crecimientoCalloId, setCrecimientoCalloId] = React.useState(null);
    const [patologiaIdentificacionId, setPatologiaIdentificacionId] = React.useState(null);
    const [patologiaNivelDanoId, setPatologiaNivelDanoId] = React.useState(null);
    const [causaPatologiaIdentificacionId, setCausaPatologiaIdentificacionId] = React.useState(null);
    const [causaPatologiaNivelAgenteDanoId, setCausaPatologiaNivelAgenteDanoId] = React.useState(null);
    const [riesgoCaidaId, setRiesgoCaidaId] = React.useState(null);
    const [susceptibilidadAreaId, setSusceptibilidadAreaId] = React.useState(null);
    const [concurrenciaAreaId, setConcurrenciaAreaId] = React.useState(null);
    const [estadoLetreroId, setEstadoLetreroId] = React.useState(null);
    const [valorEcologicoId, setValorEcologicoId] = React.useState(null);
    const [agenteDanoId, setAgenteDanoId] = React.useState(null);
    const [agenteDanoIdentificacion, setAgenteDanoIdentificacion] = React.useState(null);
    const [parasitosFungicosId, setParasitosFungicosId] = React.useState(null);
    const [parasitosFungicosIdentificacion, setParasitosFungicosIdentificacion] = React.useState(null);
    const [inclinacionId, setInclinacionId] = React.useState(null);
    const [reduccionAparatoRadicularId, setReduccionAparatoRadicularId] = React.useState(null);
    const [cavidadesId, setCavidadesId] = React.useState(null);
    const [cavidadesDimensionMediaId, setCavidadesDimensionMediaId] = React.useState(null);
    const [presenciaAguaCavidades, setPresenciaAguaCavidades] = React.useState(null);
    const [propensoRupturaId, setPropensoRupturaId] = React.useState(null);
    const [intensidadUsoAreaId, setIntensidadUsoAreaId] = React.useState(null);
    const [evaluacionBrotesEpicormicosId, setEvaluacionBrotesEpicormicosId] = React.useState([]);
    const [evaluacionRamasSecasId, setEvaluacionRamasSecasId] = React.useState([]);
    const [evaluacionDanosCopasId, setEvaluacionDanosCopasId] = React.useState([]);
    const [evaluacionDanoRadicularsId, setEvaluacionDanoRadicularsId] = React.useState([]);

    const [evaluacionAlteracionesCuelloRaizId, setEvaluacionAlteracionesCuelloRaizId] = React.useState([]);
    const [evaluacionAlteracionesInsercionRamaPrincipalId, setEvaluacionAlteracionesInsercionRamaPrincipalId] = React.useState([]);
    const [evaluacionAlteracionesRadicularesId, setEvaluacionAlteracionesRadicularesId] = React.useState([]);
    const [evaluacionAlteracionesRamasSecundariasId, setEvaluacionAlteracionesRamasSecundariasId] = React.useState([]);
    const [evaluacionAlteracionesTroncoId, setEvaluacionAlteracionesTroncoId] = React.useState([]);
    const [evaluacionCaracteristicasPatrimonialesId, setEvaluacionCaracteristicasPatrimonialesId] = React.useState([]);
    const [evaluacionCuerposFructiferosId, setEvaluacionCuerposFructiferosId] = React.useState([]);
    const [evaluacionPlanManejoId, setEvaluacionPlanManejoId] = React.useState([]);

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Inserción de evaluaciones');
            setDialogButtonText('Insertar');
            setForma(null);
            setPosicion(null);
            setUltimaPoda(null);
            setValorEstetico(null);
            setTipologiaLugar(null);
            setFertilidadSuelo(null);
            setSuelo(null);
            setIrrigacion(null);
            setDificultadOperativa(null);
            setDificultadOperativaDescripcion(null);
            setRaizBajoPavimento(null);
            setColorFollaje(null);
            setDensidadCopa(null);
            setTamanoHoja(null);
            setCrecimientoAnual(null);
            setCrecimientoCallo(null);
            setPatologiaIdentificacion(null);
            setPatologiaNivelDano(null);
            setCausaPatologiaIdentificacion(null);
            setCausaPatologiaNivelAgenteDano(null);
            setRiesgoCaida(null);
            setSusceptibilidadArea(null);
            setConcurrenciaArea(null);
            setEstadoLetrero(null);
            setValorEcologico(null);
            setAgenteDano(null);
            setParasitosFungicos(null);
            setInclinacion(null);
            setReduccionAparatoRadicular(null);
            setCavidades(null);
            setCavidadesDimensionMedia(null);
            setPropensoRuptura(null);
            setIntensidadUsoArea(null);


            catalogs.planManejos.forEach(item => {
                item.planManejoNombre = item.nombre;
                item.nota = "";
            });

            setEvaluacionPlanManejoId(catalogs.planManejos);

        }
        else {
            if (!disabledEvaluacion)
                setDialogTitle('Edición de evaluaciones');
            else
                setDialogTitle('Consulta de evaluaciones');
            setDialogButtonText('Actualizar');
            setArbolPatrimonialId(refItem.arbolPatrimonialId);
            setEvaluador(refItem.evaluador);
            setFecha(refItem.fecha);
            setDap(refItem.dap);
            setCircunferencia(refItem.circunferencia);
            setEstadoLetreroObservaciones(refItem.estadoLetreroObservaciones);
            setProyeccionCopa(refItem.proyeccionCopa);
            setAltura(refItem.altura);
            setAlturaPrimeraRama(refItem.alturaPrimeraRama);
            setTroncos(refItem.troncos);
            setFormaId(refItem.formaId);
            setPosicionId(refItem.posicionId);
            setUltimaPodaId(refItem.ultimaPodaId);
            setValorEsteticoId(refItem.valorEsteticoId);
            setTipologiaLugarId(refItem.tipologiaLugarId);
            setFertilidadSueloId(refItem.fertilidadSueloId);
            setSueloId(refItem.sueloId);
            setIrrigacionId(refItem.irrigacionId);
            setDificultadOperativaId(refItem.dificultadOperativaId);
            setDificultadOperativaDescripcionId(refItem.dificultadOperativaDescripcionId);
            setRaizBajoPavimentoId(refItem.raizBajoPavimentoId);
            setColorFollajeId(refItem.colorFollajeId);
            setDensidadCopaId(refItem.densidadCopaId);
            setTamanoHojaId(refItem.tamanoHojaId);
            setCrecimientoAnualId(refItem.crecimientoAnualId);
            setCrecimientoCalloId(refItem.crecimientoCalloId);
            setPatologiaIdentificacionId(refItem.patologiaIdentificacionId);
            setPatologiaNivelDanoId(refItem.patologiaNivelDanoId);
            setCausaPatologiaIdentificacionId(refItem.causaPatologiaIdentificacionId);
            setCausaPatologiaNivelAgenteDanoId(refItem.causaPatologiaNivelAgenteDanoId);
            setRiesgoCaidaId(refItem.riesgoCaidaId);
            setSusceptibilidadAreaId(refItem.susceptibilidadAreaId);
            setConcurrenciaAreaId(refItem.concurrenciaAreaId);
            setEstadoLetreroId(refItem.estadoLetreroId);
            setValorEcologicoId(refItem.valorEcologicoId);

            setAgenteDanoId(refItem.agenteDanoId);
            setAgenteDanoIdentificacion(refItem.agenteDanoIdentificacion);
            setParasitosFungicosId(refItem.parasitosFungicosId);
            setParasitosFungicosIdentificacion(refItem.parasitosFungicosIdentificacion);
            setInclinacionId(refItem.inclinacionId);
            setReduccionAparatoRadicularId(refItem.reduccionAparatoRadicularId);
            setCavidadesId(refItem.cavidadesId);
            setCavidadesDimensionMediaId(refItem.cavidadesDimensionMediaId);
            setPresenciaAguaCavidades(refItem.presenciaAguaCavidades);
            setPropensoRupturaId(refItem.propensoRupturaId);
            setIntensidadUsoAreaId(refItem.intensidadUsoAreaId);

            setEvaluacionBrotesEpicormicosId(refItem.evaluacionBrotesEpicormicos);
            setEvaluacionRamasSecasId(refItem.evaluacionRamasSecas);
            setEvaluacionDanosCopasId(refItem.evaluacionDanosCopas);
            setEvaluacionDanoRadicularsId(refItem.evaluacionDanoRadiculars);
            setEvaluacionAlteracionesCuelloRaizId(refItem.evaluacionAlteracionesCuelloRaiz);
            setEvaluacionAlteracionesInsercionRamaPrincipalId(refItem.evaluacionAlteracionesInsercionRamaPrincipal);
            setEvaluacionAlteracionesRadicularesId(refItem.evaluacionAlteracionesRadiculares);
            setEvaluacionAlteracionesRamasSecundariasId(refItem.evaluacionAlteracionesRamasSecundarias);
            setEvaluacionAlteracionesTroncoId(refItem.evaluacionAlteracionesTronco);
            setEvaluacionCaracteristicasPatrimonialesId(refItem.evaluacionCaracteristicasPatrimoniales);
            setEvaluacionCuerposFructiferosId(refItem.evaluacionCuerposFructiferos);
            setEvaluacionPlanManejoId(refItem.evaluacionPlanManejo);
        }
    }, [open]);

    React.useEffect(() => {
        if (createMode || !refItem || !refItem.evaluacionId || !open)
            return;
        axios.post(`${API_URL}evaluacionBrotesEpicormicos/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionBrotesEpicormicosId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionRamasSecas/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionRamasSecasId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionDanosCopa/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionDanosCopasId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionDanoRadicular/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionDanoRadicularsId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionAlteracionesCuelloRaiz/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionAlteracionesCuelloRaizId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionAlteracionesInsercionRamaPrincipal/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionAlteracionesInsercionRamaPrincipalId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionAlteracionesRadiculares/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionAlteracionesRadicularesId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionAlteracionesRamasSecundarias/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionAlteracionesRamasSecundariasId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionAlteracionesTronco/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionAlteracionesTroncoId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionCaracteristicasPatrimoniales/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionCaracteristicasPatrimonialesId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionCuerposFructiferos/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionCuerposFructiferosId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}evaluacionPlanManejo/getByEvaluacionId`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItem.evaluacionId }, { headers }).then((response) => {
            setEvaluacionPlanManejoId(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [open]);

    React.useEffect(() => {
        if (!catalogs.formas || !formaId) {
            setForma(null);
            return;
        }
        setForma(catalogs.formas.find((e) => { return e.formaId === formaId }));
    }, [formaId]);

    React.useEffect(() => {
        if (!catalogs.posicions || !posicionId) {
            setPosicion(null);
            return;
        }
        setPosicion(catalogs.posicions.find((e) => { return e.posicionId === posicionId }));
    }, [posicionId]);

    React.useEffect(() => {
        if (!catalogs.ultimaPodas || !ultimaPodaId) {
            setUltimaPoda(null);
            return;
        }
        setUltimaPoda(catalogs.ultimaPodas.find((e) => { return e.ultimaPodaId === ultimaPodaId }));
    }, [ultimaPodaId]);

    React.useEffect(() => {
        if (!catalogs.valorEsteticos || !valorEsteticoId) {
            setValorEstetico(null);
            return;
        }
        setValorEstetico(catalogs.valorEsteticos.find((e) => { return e.valorEsteticoId === valorEsteticoId }));
    }, [valorEsteticoId]);

    React.useEffect(() => {
        if (!catalogs.tipologiaLugars || !tipologiaLugarId) {
            setTipologiaLugar(null);
            return;
        }
        setTipologiaLugar(catalogs.tipologiaLugars.find((e) => { return e.tipologiaLugarId === tipologiaLugarId }));
    }, [tipologiaLugarId]);

    React.useEffect(() => {
        if (!catalogs.fertilidadSuelos || !fertilidadSueloId) {
            setFertilidadSuelo(null);
            return;
        }
        setFertilidadSuelo(catalogs.fertilidadSuelos.find((e) => { return e.fertilidadSueloId === fertilidadSueloId }));
    }, [fertilidadSueloId]);

    React.useEffect(() => {
        if (!catalogs.suelos || !sueloId) {
            setSuelo(null);
            return;
        }
        setSuelo(catalogs.suelos.find((e) => { return e.sueloId === sueloId }));
    }, [sueloId]);

    React.useEffect(() => {
        if (!catalogs.irrigacions || !irrigacionId) {
            setIrrigacion(null);
            return;
        }
        setIrrigacion(catalogs.irrigacions.find((e) => { return e.irrigacionId === irrigacionId }));
    }, [irrigacionId]);

    React.useEffect(() => {
        if (!catalogs.dificultadOperativas || !dificultadOperativaId) {
            setDificultadOperativa(null);
            return;
        }
        setDificultadOperativa(catalogs.dificultadOperativas.find((e) => { return e.dificultadOperativaId === dificultadOperativaId }));
    }, [dificultadOperativaId]);

    React.useEffect(() => {
        if (!catalogs.dificultadOperativaDescripcions || !dificultadOperativaDescripcionId) {
            setDificultadOperativaDescripcion(null);
            return;
        }
        setDificultadOperativaDescripcion(catalogs.dificultadOperativaDescripcions.find((e) => { return e.dificultadOperativaDescripcionId === dificultadOperativaDescripcionId }));
    }, [dificultadOperativaDescripcionId]);

    React.useEffect(() => {
        if (!catalogs.raizBajoPavimentos || !raizBajoPavimentoId) {
            setRaizBajoPavimento(null);
            return;
        }
        setRaizBajoPavimento(catalogs.raizBajoPavimentos.find((e) => { return e.raizBajoPavimentoId === raizBajoPavimentoId }));
    }, [raizBajoPavimentoId]);

    React.useEffect(() => {
        if (!catalogs.colorFollajes || !colorFollajeId) {
            setColorFollaje(null);
            return;
        }
        setColorFollaje(catalogs.colorFollajes.find((e) => { return e.colorFollajeId === colorFollajeId }));
    }, [colorFollajeId]);

    React.useEffect(() => {
        if (!catalogs.densidadCopas || !densidadCopaId) {
            setDensidadCopa(null);
            return;
        }
        setDensidadCopa(catalogs.densidadCopas.find((e) => { return e.densidadCopaId === densidadCopaId }));
    }, [densidadCopaId]);

    React.useEffect(() => {
        if (!catalogs.tamanoHojas || !tamanoHojaId) {
            setTamanoHoja(null);
            return;
        }
        setTamanoHoja(catalogs.tamanoHojas.find((e) => { return e.tamanoHojaId === tamanoHojaId }));
    }, [tamanoHojaId]);

    React.useEffect(() => {
        if (!catalogs.crecimientoAnuals || !crecimientoAnualId) {
            setCrecimientoAnual(null);
            return;
        }
        setCrecimientoAnual(catalogs.crecimientoAnuals.find((e) => { return e.crecimientoAnualId === crecimientoAnualId }));
    }, [crecimientoAnualId]);

    React.useEffect(() => {
        if (!catalogs.crecimientoCallos || !crecimientoCalloId) {
            setCrecimientoCallo(null);
            return;
        }
        setCrecimientoCallo(catalogs.crecimientoCallos.find((e) => { return e.crecimientoCalloId === crecimientoCalloId }));
    }, [crecimientoCalloId]);

    React.useEffect(() => {
        if (!catalogs.patologiaIdentificacions || !patologiaIdentificacionId) {
            setPatologiaIdentificacion(null);
            return;
        }
        setPatologiaIdentificacion(catalogs.patologiaIdentificacions.find((e) => { return e.patologiaIdentificacionId === patologiaIdentificacionId }));
    }, [patologiaIdentificacionId]);

    React.useEffect(() => {
        if (!catalogs.patologiaNivelDanos || !patologiaNivelDanoId) {
            setPatologiaNivelDano(null);
            return;
        }
        setPatologiaNivelDano(catalogs.patologiaNivelDanos.find((e) => { return e.patologiaNivelDanoId === patologiaNivelDanoId }));
    }, [patologiaNivelDanoId]);

    React.useEffect(() => {
        if (!catalogs.causaPatologiaIdentificacions || !causaPatologiaIdentificacionId) {
            setCausaPatologiaIdentificacion(null);
            return;
        }
        setCausaPatologiaIdentificacion(catalogs.causaPatologiaIdentificacions.find((e) => { return e.causaPatologiaIdentificacionId === causaPatologiaIdentificacionId }));
    }, [causaPatologiaIdentificacionId]);

    React.useEffect(() => {
        if (!catalogs.causaPatologiaNivelAgenteDanos || !causaPatologiaNivelAgenteDanoId) {
            setCausaPatologiaNivelAgenteDano(null);
            return;
        }
        setCausaPatologiaNivelAgenteDano(catalogs.causaPatologiaNivelAgenteDanos.find((e) => { return e.causaPatologiaNivelAgenteDanoId === causaPatologiaNivelAgenteDanoId }));
    }, [causaPatologiaNivelAgenteDanoId]);

    React.useEffect(() => {
        if (!catalogs.riesgoCaidas || !riesgoCaidaId) {
            setRiesgoCaida(null);
            return;
        }
        setRiesgoCaida(catalogs.riesgoCaidas.find((e) => { return e.riesgoCaidaId === riesgoCaidaId }));
    }, [riesgoCaidaId]);

    React.useEffect(() => {
        if (!catalogs.susceptibilidadAreas || !susceptibilidadAreaId) {
            setSusceptibilidadArea(null);
            return;
        }
        setSusceptibilidadArea(catalogs.susceptibilidadAreas.find((e) => { return e.susceptibilidadAreaId === susceptibilidadAreaId }));
    }, [susceptibilidadAreaId]);

    React.useEffect(() => {
        if (!catalogs.concurrenciaAreas || !concurrenciaAreaId) {
            setConcurrenciaArea(null);
            return;
        }
        setConcurrenciaArea(catalogs.concurrenciaAreas.find((e) => { return e.concurrenciaAreaId === concurrenciaAreaId }));
    }, [concurrenciaAreaId]);

    React.useEffect(() => {
        if (!catalogs.estadoLetreros || !estadoLetreroId) {
            setEstadoLetrero(null);
            return;
        }
        setEstadoLetrero(catalogs.estadoLetreros.find((e) => { return e.estadoLetreroId === estadoLetreroId }));
    }, [estadoLetreroId]);

    React.useEffect(() => {
        if (!catalogs.valorEcologicos || !valorEcologicoId) {
            setValorEcologico(null);
            return;
        }
        setValorEcologico(catalogs.valorEcologicos.find((e) => { return e.valorEcologicoId === valorEcologicoId }));
    }, [valorEcologicoId]);

    React.useEffect(() => {
        if (!catalogs.agenteDanos || !agenteDanoId) {
            setAgenteDano(null);
            return;
        }
        setAgenteDano(catalogs.agenteDanos.find((e) => { return e.agenteDanoId === agenteDanoId }));
    }, [agenteDanoId]);

    React.useEffect(() => {
        if (!catalogs.parasitosFungicoss || !parasitosFungicosId) {
            setParasitosFungicos(null);
            return;
        }
        setParasitosFungicos(catalogs.parasitosFungicoss.find((e) => { return e.parasitosFungicosId === parasitosFungicosId }));
    }, [parasitosFungicosId]);

    React.useEffect(() => {
        if (!catalogs.inclinacions || !inclinacionId) {
            setInclinacion(null);
            return;
        }
        setInclinacion(catalogs.inclinacions.find((e) => { return e.inclinacionId === inclinacionId }));
    }, [inclinacionId]);

    React.useEffect(() => {
        if (!catalogs.reduccionAparatoRadiculars || !reduccionAparatoRadicularId) {
            setReduccionAparatoRadicular(null);
            return;
        }
        setReduccionAparatoRadicular(catalogs.reduccionAparatoRadiculars.find((e) => { return e.reduccionAparatoRadicularId === reduccionAparatoRadicularId }));
    }, [reduccionAparatoRadicularId]);

    React.useEffect(() => {
        if (!catalogs.cavidadess || !cavidadesId) {
            setCavidades(null);
            return;
        }
        setCavidades(catalogs.cavidadess.find((e) => { return e.cavidadesId === cavidadesId }));
    }, [cavidadesId]);

    React.useEffect(() => {
        if (!catalogs.cavidadesDimensionMedias || !cavidadesDimensionMediaId) {
            setCavidadesDimensionMedia(null);
            return;
        }
        setCavidadesDimensionMedia(catalogs.cavidadesDimensionMedias.find((e) => { return e.cavidadesDimensionMediaId === cavidadesDimensionMediaId }));
    }, [cavidadesDimensionMediaId]);

    React.useEffect(() => {
        if (!catalogs.propensoRupturas || !propensoRupturaId) {
            setPropensoRuptura(null);
            return;
        }
        setPropensoRuptura(catalogs.propensoRupturas.find((e) => { return e.propensoRupturaId === propensoRupturaId }));
    }, [propensoRupturaId]);

    React.useEffect(() => {
        if (!catalogs.intensidadUsoAreas || !intensidadUsoAreaId) {
            setIntensidadUsoArea(null);
            return;
        }
        setIntensidadUsoArea(catalogs.intensidadUsoAreas.find((e) => { return e.intensidadUsoAreaId === intensidadUsoAreaId }));
    }, [intensidadUsoAreaId]);

    React.useEffect(() => {
        if (!catalogs.brotesEpicormicos || !Array.isArray(evaluacionBrotesEpicormicosId)) {
            setBroteEpicormico([]);
            return;
        }
        setBroteEpicormico(catalogs.brotesEpicormicos.filter(({ brotesEpicormicosId: id1 }) => evaluacionBrotesEpicormicosId.some(({ brotesEpicormicosId: id2 }) => id2 === id1)));
    }, [evaluacionBrotesEpicormicosId]);

    React.useEffect(() => {
        if (!catalogs.ramasSecas || !Array.isArray(evaluacionRamasSecasId)) {
            setRamaSeca([]);
            return;
        }
        setRamaSeca(catalogs.ramasSecas.filter(({ ramasSecasId: id1 }) => evaluacionRamasSecasId.some(({ ramasSecasId: id2 }) => id2 === id1)));
    }, [evaluacionRamasSecasId]);

    React.useEffect(() => {
        if (!catalogs.danosCopas || !Array.isArray(evaluacionDanosCopasId)) {
            setDanoCopa([]);
            return;
        }
        setDanoCopa(catalogs.danosCopas.filter(({ danosCopaId: id1 }) => evaluacionDanosCopasId.some(({ danosCopaId: id2 }) => id2 === id1)));
    }, [evaluacionDanosCopasId]);

    React.useEffect(() => {
        if (!catalogs.danoRadiculars || !Array.isArray(evaluacionDanoRadicularsId)) {
            setDanoRadicular([]);
            return;
        }
        setDanoRadicular(catalogs.danoRadiculars.filter(({ danoRadicularId: id1 }) => evaluacionDanoRadicularsId.some(({ danoRadicularId: id2 }) => id2 === id1)));
    }, [evaluacionDanoRadicularsId]);


    React.useEffect(() => {
        if (!catalogs.alteracionesCuelloRaiz || !Array.isArray(evaluacionAlteracionesCuelloRaizId)) {
            setAlteracionesCuelloRaiz([]);
            return;
        }
        setAlteracionesCuelloRaiz(catalogs.alteracionesCuelloRaiz.filter(({ alteracionesCuelloRaizId: id1 }) => evaluacionAlteracionesCuelloRaizId.some(({ alteracionesCuelloRaizId: id2 }) => id2 === id1)));
    }, [evaluacionAlteracionesCuelloRaizId]);

    React.useEffect(() => {
        if (!catalogs.alteracionesInsercionRamaPrincipal || !Array.isArray(evaluacionAlteracionesInsercionRamaPrincipalId)) {
            setAlteracionesInsercionRamaPrincipal([]);
            return;
        }
        setAlteracionesInsercionRamaPrincipal(catalogs.alteracionesInsercionRamaPrincipal.filter(({ alteracionesInsercionRamaPrincipalId: id1 }) => evaluacionAlteracionesInsercionRamaPrincipalId.some(({ alteracionesInsercionRamaPrincipalId: id2 }) => id2 === id1)));
    }, [evaluacionAlteracionesInsercionRamaPrincipalId]);

    React.useEffect(() => {
        if (!catalogs.alteracionesRadiculares || !Array.isArray(evaluacionAlteracionesRadicularesId)) {
            setAlteracionesRadiculares([]);
            return;
        }
        setAlteracionesRadiculares(catalogs.alteracionesRadiculares.filter(({ alteracionesRadicularesId: id1 }) => evaluacionAlteracionesRadicularesId.some(({ alteracionesRadicularesId: id2 }) => id2 === id1)));
    }, [evaluacionAlteracionesRadicularesId]);

    React.useEffect(() => {
        if (!catalogs.alteracionesRamasSecundarias || !Array.isArray(evaluacionAlteracionesRamasSecundariasId)) {
            setAlteracionesRamasSecundarias([]);
            return;
        }
        setAlteracionesRamasSecundarias(catalogs.alteracionesRamasSecundarias.filter(({ alteracionesRamasSecundariasId: id1 }) => evaluacionAlteracionesRamasSecundariasId.some(({ alteracionesRamasSecundariasId: id2 }) => id2 === id1)));
    }, [evaluacionAlteracionesRamasSecundariasId]);

    React.useEffect(() => {
        if (!catalogs.alteracionesTronco || !Array.isArray(evaluacionAlteracionesTroncoId)) {
            setAlteracionesTronco([]);
            return;
        }
        setAlteracionesTronco(catalogs.alteracionesTronco.filter(({ alteracionesTroncoId: id1 }) => evaluacionAlteracionesTroncoId.some(({ alteracionesTroncoId: id2 }) => id2 === id1)));
    }, [evaluacionAlteracionesTroncoId]);

    React.useEffect(() => {
        if (!catalogs.caracteristicasPatrimoniales || !Array.isArray(evaluacionCaracteristicasPatrimonialesId)) {
            setCaracteristicasPatrimoniales([]);
            return;
        }
        setCaracteristicasPatrimoniales(catalogs.caracteristicasPatrimoniales.filter(({ caracteristicasPatrimonialesId: id1 }) => evaluacionCaracteristicasPatrimonialesId.some(({ caracteristicasPatrimonialesId: id2 }) => id2 === id1)));
    }, [evaluacionCaracteristicasPatrimonialesId]);

    React.useEffect(() => {
        if (!catalogs.cuerposFructiferos || !Array.isArray(evaluacionCuerposFructiferosId)) {
            setCuerposFructiferos([]);
            return;
        }
        setCuerposFructiferos(catalogs.cuerposFructiferos.filter(({ cuerposFructiferosId: id1 }) => evaluacionCuerposFructiferosId.some(({ cuerposFructiferosId: id2 }) => id2 === id1)));
    }, [evaluacionCuerposFructiferosId]);

    React.useEffect(() => {
        if (!catalogs.planManejos || !Array.isArray(evaluacionPlanManejoId)) {
            setPlanManejo([]);
            return;
        }
        setPlanManejo(catalogs.planManejos.filter(({ planManejoId: id1 }) => evaluacionPlanManejoId.some(({ planManejoId: id2 }) => id2 === id1)));
    }, [evaluacionPlanManejoId]);


    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.arbolPatrimonialId = createMode ? arbolItem.arbolPatrimonialId : arbolPatrimonialId;
        newItem.evaluador = evaluador;
        newItem.fecha = fecha;
        newItem.dap = dap;
        newItem.circunferencia = circunferencia;
        newItem.estadoLetreroObservaciones = estadoLetreroObservaciones;
        newItem.proyeccionCopa = proyeccionCopa;
        newItem.altura = altura;
        newItem.alturaPrimeraRama = alturaPrimeraRama;
        newItem.troncos = troncos;
        newItem.formaId = forma == null ? null : forma.formaId;
        newItem.posicionId = posicion == null ? null : posicion.posicionId;
        newItem.ultimaPodaId = ultimaPoda == null ? null : ultimaPoda.ultimaPodaId;
        newItem.valorEsteticoId = valorEstetico == null ? null : valorEstetico.valorEsteticoId;
        newItem.tipologiaLugarId = tipologiaLugar == null ? null : tipologiaLugar.tipologiaLugarId;
        newItem.fertilidadSueloId = fertilidadSuelo == null ? null : fertilidadSuelo.fertilidadSueloId;
        newItem.sueloId = suelo == null ? null : suelo.sueloId;
        newItem.irrigacionId = irrigacion == null ? null : irrigacion.irrigacionId;
        newItem.dificultadOperativaId = dificultadOperativa == null ? null : dificultadOperativa.dificultadOperativaId;
        newItem.dificultadOperativaDescripcionId = dificultadOperativaDescripcion == null ? null : dificultadOperativaDescripcion.dificultadOperativaDescripcionId;
        newItem.raizBajoPavimentoId = raizBajoPavimento == null ? null : raizBajoPavimento.raizBajoPavimentoId;
        newItem.colorFollajeId = colorFollaje == null ? null : colorFollaje.colorFollajeId;
        newItem.densidadCopaId = densidadCopa == null ? null : densidadCopa.densidadCopaId;
        newItem.tamanoHojaId = tamanoHoja == null ? null : tamanoHoja.tamanoHojaId;
        newItem.crecimientoAnualId = crecimientoAnual == null ? null : crecimientoAnual.crecimientoAnualId;
        newItem.crecimientoCalloId = crecimientoCallo == null ? null : crecimientoCallo.crecimientoCalloId;
        newItem.patologiaIdentificacionId = patologiaIdentificacion == null ? null : patologiaIdentificacion.patologiaIdentificacionId;
        newItem.patologiaNivelDanoId = patologiaNivelDano == null ? null : patologiaNivelDano.patologiaNivelDanoId;
        newItem.causaPatologiaIdentificacionId = causaPatologiaIdentificacion == null ? null : causaPatologiaIdentificacion.causaPatologiaIdentificacionId;
        newItem.causaPatologiaNivelAgenteDanoId = causaPatologiaNivelAgenteDano == null ? null : causaPatologiaNivelAgenteDano.causaPatologiaNivelAgenteDanoId;
        newItem.riesgoCaidaId = riesgoCaida == null ? null : riesgoCaida.riesgoCaidaId;
        newItem.susceptibilidadAreaId = susceptibilidadArea == null ? null : susceptibilidadArea.susceptibilidadAreaId;
        newItem.concurrenciaAreaId = concurrenciaArea == null ? null : concurrenciaArea.concurrenciaAreaId;
        newItem.estadoLetreroId = estadoLetrero == null ? null : estadoLetrero.estadoLetreroId;
        newItem.valorEcologicoId = valorEcologico == null ? null : valorEcologico.valorEcologicoId;

        newItem.agenteDanoId = agenteDano == null ? null : agenteDano.agenteDanoId;
        newItem.agenteDanoIdentificacion = agenteDanoIdentificacion;
        newItem.parasitosFungicosId = parasitosFungicos == null ? null : parasitosFungicos.parasitosFungicosId;
        newItem.parasitosFungicosIdentificacion = parasitosFungicosIdentificacion;
        newItem.inclinacionId = inclinacion == null ? null : inclinacion.inclinacionId;
        newItem.reduccionAparatoRadicularId = reduccionAparatoRadicular == null ? null : reduccionAparatoRadicular.reduccionAparatoRadicularId;
        newItem.cavidadesId = cavidades == null ? null : cavidades.cavidadesId;
        newItem.cavidadesDimensionMediaId = cavidadesDimensionMedia == null ? null : cavidadesDimensionMedia.cavidadesDimensionMediaId;
        newItem.presenciaAguaCavidades = presenciaAguaCavidades;
        newItem.propensoRupturaId = propensoRuptura == null ? null : propensoRuptura.propensoRupturaId;

        newItem.intensidadUsoAreaId = intensidadUsoArea == null ? null : intensidadUsoArea.intensidadUsoAreaId;
        newItem.evaluacionBrotesEpicormicos = broteEpicormico;
        newItem.evaluacionRamasSecas = ramaSeca;
        newItem.evaluacionDanosCopa = danoCopa;
        newItem.evaluacionDanoRadicular = danoRadicular;
        newItem.evaluacionAlteracionesCuelloRaiz = alteracionesCuelloRaiz;
        newItem.evaluacionAlteracionesInsercionRamaPrincipal = alteracionesInsercionRamaPrincipal;
        newItem.evaluacionAlteracionesRadiculares = alteracionesRadiculares;
        newItem.evaluacionAlteracionesRamasSecundarias = alteracionesRamasSecundarias;
        newItem.evaluacionAlteracionesTronco = alteracionesTronco;
        newItem.evaluacionCaracteristicasPatrimoniales = caracteristicasPatrimoniales;
        newItem.evaluacionCuerposFructiferos = cuerposFructiferos;
        newItem.evaluacionPlanManejo = evaluacionPlanManejoId;

        newItem.evaluacionTipoIntervencion = [];

        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = 1;

        if (createMode) {
            newItem.evaluacionId = 0;
            axios.post(`${API_URL}evaluacion/create`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login"); 
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}evaluacion/update`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login"); 
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    if (!refItem)
        return null;

    return (
        <React.Fragment>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={<Typography color="primary" align='center' width={"100%"} variant={'h2'}>{dialogTitle}</Typography>}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Div>
                                <Grid container >
                                    <Grid item xs={6} md={6} lg={6} textAlign={"center"} >
                                        <Typography color="primary" variant={'h4'} >Arbol patrimonial: {refItem.arbolPatrimonialCodigo}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} textAlign={"center"} >
                                        <Typography color="primary" variant={'h4'} >Evaluación: {indexEvaluacion + 1}</Typography>
                                    </Grid>
                                </Grid>
                            </Div>
                            <Box p={2} spacing={3}>
                                <form ref={formRef}>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"General"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={6} md={6} lg={6} >
                                                <TextField disabled={disabledEvaluacion} required fullWidth id='outlined' label='Evaluador' defaultValue={refItem.evaluador} inputProps={{ maxLength: 100 }} onChange={(event) => { setEvaluador(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} >
                                                <TextField disabled={disabledEvaluacion} required fullWidth id='outlined' label='Fecha' defaultValue={refItem.fecha ? refItem.fecha.slice(0, 10) : null} type='date' InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 10, format: "dd/mm/yyyy" }} onChange={(event) => { setFecha(event.target.value); }} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Circunferencia' defaultValue={refItem.circunferencia} type='number' inputProps={{ min: -99999999.99, max: 99999999.99, step: 0.01 }} onChange={(event) => { setCircunferencia(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Diámetro a la altura del pecho' defaultValue={refItem.dap} type='number' inputProps={{ min: -99999999.99, max: 99999999.99, step: 0.01 }} onChange={(event) => { setDap(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Ancho de copa' defaultValue={refItem.proyeccionCopa} type='number' inputProps={{ min: -99999999.99, max: 99999999.99, step: 0.01 }} onChange={(event) => { setProyeccionCopa(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Altura' defaultValue={refItem.altura} type='number' inputProps={{ min: -99999999.99, max: 99999999.99, step: 0.01 }} onChange={(event) => { setAltura(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Altura a la primera rama' defaultValue={refItem.alturaPrimeraRama} type='number' inputProps={{ min: -99999999.99, max: 99999999.99, step: 0.01 }} onChange={(event) => { setAlturaPrimeraRama(event.target.value); }} />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Troncos' defaultValue={refItem.troncos} type='number' inputProps={{ min: -2147483648, max: 2147483647, step: 1 }} onChange={(event) => { setTroncos(event.target.value); }} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.formas}
                                                    value={forma}
                                                    onChange={(event, newValue) => {
                                                        setForma(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Forma" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.posicions}
                                                    value={posicion}
                                                    onChange={(event, newValue) => {
                                                        setPosicion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Posición" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.ultimaPodas}
                                                    value={ultimaPoda}
                                                    onChange={(event, newValue) => {
                                                        setUltimaPoda(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Última poda" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.valorEsteticos}
                                                    value={valorEstetico}
                                                    onChange={(event, newValue) => {
                                                        setValorEstetico(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Valor estético" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Lugar"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.tipologiaLugars}
                                                    value={tipologiaLugar}
                                                    onChange={(event, newValue) => {
                                                        setTipologiaLugar(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Entorno" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.fertilidadSuelos}
                                                    value={fertilidadSuelo}
                                                    onChange={(event, newValue) => {
                                                        setFertilidadSuelo(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Fertilidad" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.suelos}
                                                    value={suelo}
                                                    onChange={(event, newValue) => {
                                                        setSuelo(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Suelo" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.irrigacions}
                                                    value={irrigacion}
                                                    onChange={(event, newValue) => {
                                                        setIrrigacion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Irrigación" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.dificultadOperativas}
                                                    value={dificultadOperativa}
                                                    onChange={(event, newValue) => {
                                                        setDificultadOperativa(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Dificultad de intervención" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.dificultadOperativaDescripcions}
                                                    value={dificultadOperativaDescripcion}
                                                    onChange={(event, newValue) => {
                                                        setDificultadOperativaDescripcion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Motivo dificultad de intervención" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.raizBajoPavimentos}
                                                    value={raizBajoPavimento}
                                                    onChange={(event, newValue) => {
                                                        setRaizBajoPavimento(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Raíz bajo el pavimento" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Fitopatología"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={2} md={2} lg={2} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.colorFollajes}
                                                    value={colorFollaje}
                                                    onChange={(event, newValue) => {
                                                        setColorFollaje(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Color de hojas" />}
                                                />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.densidadCopas}
                                                    value={densidadCopa}
                                                    onChange={(event, newValue) => {
                                                        setDensidadCopa(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Densidad de copa" />}
                                                />
                                            </Grid>
                                            <Grid item xs={2} md={2} lg={2} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.tamanoHojas}
                                                    value={tamanoHoja}
                                                    onChange={(event, newValue) => {
                                                        setTamanoHoja(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Tamaño de hojas" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.crecimientoAnuals}
                                                    value={crecimientoAnual}
                                                    onChange={(event, newValue) => {
                                                        setCrecimientoAnual(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Crecimiento anual" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.crecimientoCallos}
                                                    value={crecimientoCallo}
                                                    onChange={(event, newValue) => {
                                                        setCrecimientoCallo(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Crecimiento del callo" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.brotesEpicormicos}
                                                    value={broteEpicormico}
                                                    onChange={(event, newValue) => {
                                                        setBroteEpicormico(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Brotes epicórmicos" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.ramasSecas}
                                                    value={ramaSeca}
                                                    onChange={(event, newValue) => {
                                                        setRamaSeca(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Ramas secas" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.danosCopas}
                                                    value={danoCopa}
                                                    onChange={(event, newValue) => {
                                                        setDanoCopa(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Daños en la copa" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.danoRadiculars}
                                                    value={danoRadicular}
                                                    onChange={(event, newValue) => {
                                                        setDanoRadicular(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Daño radicular" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.patologiaIdentificacions}
                                                    value={patologiaIdentificacion}
                                                    onChange={(event, newValue) => {
                                                        setPatologiaIdentificacion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Identificación patógeno" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.patologiaNivelDanos}
                                                    value={patologiaNivelDano}
                                                    onChange={(event, newValue) => {
                                                        setPatologiaNivelDano(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Nivel daño patógeno" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.causaPatologiaIdentificacions}
                                                    value={causaPatologiaIdentificacion}
                                                    onChange={(event, newValue) => {
                                                        setCausaPatologiaIdentificacion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Identificación de agente de daño" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.causaPatologiaNivelAgenteDanos}
                                                    value={causaPatologiaNivelAgenteDano}
                                                    onChange={(event, newValue) => {
                                                        setCausaPatologiaNivelAgenteDano(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Nivel de agente de daño" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Mecánica"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.inclinacions}
                                                    value={inclinacion}
                                                    onChange={(event, newValue) => {
                                                        setInclinacion(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Inclinación" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.reduccionAparatoRadiculars}
                                                    value={reduccionAparatoRadicular}
                                                    onChange={(event, newValue) => {
                                                        setReduccionAparatoRadicular(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Reducción del aparato radicular" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.alteracionesRadiculares}
                                                    value={alteracionesRadiculares}
                                                    onChange={(event, newValue) => {
                                                        setAlteracionesRadiculares(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Alteraciones radiculares" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.alteracionesCuelloRaiz}
                                                    value={alteracionesCuelloRaiz}
                                                    onChange={(event, newValue) => {
                                                        setAlteracionesCuelloRaiz(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Alteraciones del cuello de la raíz" />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.alteracionesTronco}
                                                    value={alteracionesTronco}
                                                    onChange={(event, newValue) => {
                                                        setAlteracionesTronco(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Alteraciones del tronco" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.alteracionesInsercionRamaPrincipal}
                                                    value={alteracionesInsercionRamaPrincipal}
                                                    onChange={(event, newValue) => {
                                                        setAlteracionesInsercionRamaPrincipal(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Alteraciones en la inserción de rama principal" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.alteracionesRamasSecundarias}
                                                    value={alteracionesRamasSecundarias}
                                                    onChange={(event, newValue) => {
                                                        setAlteracionesRamasSecundarias(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Alteraciones de ramas secundarias" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3} lg={3} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.cuerposFructiferos}
                                                    value={cuerposFructiferos}
                                                    onChange={(event, newValue) => {
                                                        setCuerposFructiferos(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Cuerpos fructíferos" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Riesgo"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.riesgoCaidas}
                                                    value={riesgoCaida}
                                                    onChange={(event, newValue) => {
                                                        setRiesgoCaida(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Riesgo de caída" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.susceptibilidadAreas}
                                                    value={susceptibilidadArea}
                                                    onChange={(event, newValue) => {
                                                        setSusceptibilidadArea(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Susceptibilidad del área" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} lg={4} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.concurrenciaAreas}
                                                    value={concurrenciaArea}
                                                    onChange={(event, newValue) => {
                                                        setConcurrenciaArea(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Concurrencia del área" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Tratamiento sugerido"}
                                        />
                                        <div>
                                            {
                                                evaluacionPlanManejoId && Array.isArray(evaluacionPlanManejoId) && evaluacionPlanManejoId.map((evaluacionPlanManejo, key) => (<EvaluacionPlanManejoItem item={evaluacionPlanManejo} items={evaluacionPlanManejoId} setItems={setEvaluacionPlanManejoId} disabledEvaluacion={disabledEvaluacion} />))
                                            }
                                        </div>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Características patrimoniales"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={12} md={12} lg={12} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    multiple
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.caracteristicasPatrimoniales}
                                                    value={caracteristicasPatrimoniales}
                                                    onChange={(event, newValue) => {
                                                        setCaracteristicasPatrimoniales(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Características patrimoniales" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Estado del letrero"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={6} md={6} lg={6} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.estadoLetreros}
                                                    value={estadoLetrero}
                                                    onChange={(event, newValue) => {
                                                        setEstadoLetrero(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Estado del letrero" />}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} >
                                                <TextField disabled={disabledEvaluacion} fullWidth id='outlined' label='Observaciones' defaultValue={refItem.estadoLetreroObservaciones} inputProps={{ maxLength: 3000 }} onChange={(event) => { setEstadoLetreroObservaciones(event.target.value); }} />
                                            </Grid>
                                        </Grid>
                                    </Div>
                                    <Div wrapperSx={{ backgroundColor: 'background.paper', pt: 1 }}>
                                        <CardHeader
                                            title={"Ecología"}
                                        />
                                        <Grid container spacing={3} p={1} >
                                            <Grid item xs={6} md={6} lg={6} >
                                                <Autocomplete
                                                    disabled={disabledEvaluacion}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(o) => o.nombre}
                                                    options={catalogs.valorEcologicos}
                                                    value={valorEcologico}
                                                    onChange={(event, newValue) => {
                                                        setValorEcologico(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Valor ecológico" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Div>

                                    {!disabledEvaluacion && <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>}
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment >
    );
};
export default EvaluacionCustom;
