import React from "react";
import Page from "@jumbo/shared/Page";
import RaizBajoPavimento from "app/pages/catalogos/RaizBajoPavimento";
import RamasSecas from "app/pages/catalogos/RamasSecas";
import ReduccionAparatoRadicular from "app/pages/catalogos/ReduccionAparatoRadicular";
import Suelo from "app/pages/catalogos/Suelo";
import EstadoLetrero from "app/pages/catalogos/EstadoLetrero";
import TipologiaLugar from "app/pages/catalogos/TipologiaLugar";
import EvaluacionPlanManejo from "app/pages/catalogos/EvaluacionPlanManejo";
import UltimaPoda from "app/pages/catalogos/UltimaPoda";
import Evaluacion from "app/pages/catalogos/Evaluacion";
import ValorEstetico from "app/pages/catalogos/ValorEstetico";
import Sector from "app/pages/catalogos/Sector";
import AgenteDano from "app/pages/catalogos/AgenteDano";
import AlteracionesCuelloRaiz from "app/pages/catalogos/AlteracionesCuelloRaiz";
import Intervencion from "app/pages/catalogos/Intervencion";
import Especie from "app/pages/catalogos/Especie";
import AlteracionesInsercionRamaPrincipal from "app/pages/catalogos/AlteracionesInsercionRamaPrincipal";
import IntervencionPlanManejo from "app/pages/catalogos/IntervencionPlanManejo";
import AlteracionesRadiculares from "app/pages/catalogos/AlteracionesRadiculares";
import PatologiaIdentificacion from "app/pages/catalogos/PatologiaIdentificacion";
import AlteracionesRamasSecundarias from "app/pages/catalogos/AlteracionesRamasSecundarias";
import PatologiaNivelDano from "app/pages/catalogos/PatologiaNivelDano";
import AlteracionesTronco from "app/pages/catalogos/AlteracionesTronco";
import Usuario from "app/pages/catalogos/Usuario";
import Posicion from "app/pages/catalogos/Posicion";
import BrotesEpicormicos from "app/pages/catalogos/BrotesEpicormicos";
import CausaPatologiaNivelAgenteDano from "app/pages/catalogos/CausaPatologiaNivelAgenteDano";
import DificultadOperativaDescripcion from "app/pages/catalogos/DificultadOperativaDescripcion";
import TamanoHoja from "app/pages/catalogos/TamanoHoja";
import ValorEcologico from "app/pages/catalogos/ValorEcologico";
import ColorFollaje from "app/pages/catalogos/ColorFollaje";
import CaracteristicasPatrimoniales from "app/pages/catalogos/CaracteristicasPatrimoniales";
import CrecimientoAnual from "app/pages/catalogos/CrecimientoAnual";
import CrecimientoCallo from "app/pages/catalogos/CrecimientoCallo";
import EvaluacionBrotesEpicormicos from "app/pages/catalogos/EvaluacionBrotesEpicormicos";
import EvaluacionRamasSecas from "app/pages/catalogos/EvaluacionRamasSecas";
import CuerposFructiferos from "app/pages/catalogos/CuerposFructiferos";
import EvaluacionDanosCopa from "app/pages/catalogos/EvaluacionDanosCopa";
import DanoRadicular from "app/pages/catalogos/DanoRadicular";
import EvaluacionDanoRadicular from "app/pages/catalogos/EvaluacionDanoRadicular";
import EvaluacionAlteracionesRadiculares from "app/pages/catalogos/EvaluacionAlteracionesRadiculares";
import DanosCopa from "app/pages/catalogos/DanosCopa";
import EvaluacionAlteracionesCuelloRaiz from "app/pages/catalogos/EvaluacionAlteracionesCuelloRaiz";
import DensidadCopa from "app/pages/catalogos/DensidadCopa";
import EvaluacionAlteracionesTronco from "app/pages/catalogos/EvaluacionAlteracionesTronco";
import EvaluacionAlteracionesInsercionRamaPrincipal from "app/pages/catalogos/EvaluacionAlteracionesInsercionRamaPrincipal";
import DificultadOperativa from "app/pages/catalogos/DificultadOperativa";
import EvaluacionAlteracionesRamasSecundarias from "app/pages/catalogos/EvaluacionAlteracionesRamasSecundarias";
import DimensionHojas from "app/pages/catalogos/DimensionHojas";
import EvaluacionCuerposFructiferos from "app/pages/catalogos/EvaluacionCuerposFructiferos";
import FertilidadSuelo from "app/pages/catalogos/FertilidadSuelo";
import EvaluacionCaracteristicasPatrimoniales from "app/pages/catalogos/EvaluacionCaracteristicasPatrimoniales";
import Forma from "app/pages/catalogos/Forma";
import Inclinacion from "app/pages/catalogos/Inclinacion";
import CausaPatologiaIdentificacion from "app/pages/catalogos/CausaPatologiaIdentificacion";
import RiesgoCaida from "app/pages/catalogos/RiesgoCaida";
import Irrigacion from "app/pages/catalogos/Irrigacion";
import SusceptibilidadArea from "app/pages/catalogos/SusceptibilidadArea";
import ConcurrenciaArea from "app/pages/catalogos/ConcurrenciaArea";
import PlanManejo from "app/pages/catalogos/PlanManejo";
import ArbolPatrimonial from "app/pages/catalogos/ArbolPatrimonial";
import ReporteGeneral from "app/pages/catalogos/ReporteGeneral";
import FichaGeneralPdf from "app/pages/catalogos/FichaGeneralPdf";
import UsuarioPerfil from "app/pages/catalogos/UsuarioPerfil";
const catalogosRoutes = [
    {
        path: "/catalogos/RaizBajoPavimento",
        element: <Page component={RaizBajoPavimento} />,
    },
    {
        path: "/catalogos/RamasSecas",
        element: <Page component={RamasSecas} />,
    },
    {
        path: "/catalogos/ReduccionAparatoRadicular",
        element: <Page component={ReduccionAparatoRadicular} />,
    },
    {
        path: "/catalogos/Suelo",
        element: <Page component={Suelo} />,
    },
    {
        path: "/catalogos/EstadoLetrero",
        element: <Page component={EstadoLetrero} />,
    },
    {
        path: "/catalogos/TipologiaLugar",
        element: <Page component={TipologiaLugar} />,
    },
    {
        path: "/catalogos/EvaluacionPlanManejo",
        element: <Page component={EvaluacionPlanManejo} />,
    },
    {
        path: "/catalogos/UltimaPoda",
        element: <Page component={UltimaPoda} />,
    },
    {
        path: "/catalogos/Evaluacion",
        element: <Page component={Evaluacion} />,
    },
    {
        path: "/catalogos/ValorEstetico",
        element: <Page component={ValorEstetico} />,
    },
    {
        path: "/catalogos/Sector",
        element: <Page component={Sector} />,
    },
    {
        path: "/catalogos/AgenteDano",
        element: <Page component={AgenteDano} />,
    },
    {
        path: "/catalogos/AlteracionesCuelloRaiz",
        element: <Page component={AlteracionesCuelloRaiz} />,
    },
    {
        path: "/catalogos/Intervencion",
        element: <Page component={Intervencion} />,
    },
    {
        path: "/catalogos/Especie",
        element: <Page component={Especie} />,
    },
    {
        path: "/catalogos/AlteracionesInsercionRamaPrincipal",
        element: <Page component={AlteracionesInsercionRamaPrincipal} />,
    },
    {
        path: "/catalogos/IntervencionPlanManejo",
        element: <Page component={IntervencionPlanManejo} />,
    },
    {
        path: "/catalogos/AlteracionesRadiculares",
        element: <Page component={AlteracionesRadiculares} />,
    },
    {
        path: "/catalogos/PatologiaIdentificacion",
        element: <Page component={PatologiaIdentificacion} />,
    },
    {
        path: "/catalogos/AlteracionesRamasSecundarias",
        element: <Page component={AlteracionesRamasSecundarias} />,
    },
    {
        path: "/catalogos/PatologiaNivelDano",
        element: <Page component={PatologiaNivelDano} />,
    },
    {
        path: "/catalogos/AlteracionesTronco",
        element: <Page component={AlteracionesTronco} />,
    },
    {
        path: "/catalogos/Usuario",
        element: <Page component={Usuario} />,
    },
    {
        path: "/catalogos/Posicion",
        element: <Page component={Posicion} />,
    },
    {
        path: "/catalogos/BrotesEpicormicos",
        element: <Page component={BrotesEpicormicos} />,
    },
    {
        path: "/catalogos/CausaPatologiaNivelAgenteDano",
        element: <Page component={CausaPatologiaNivelAgenteDano} />,
    },
    {
        path: "/catalogos/DificultadOperativaDescripcion",
        element: <Page component={DificultadOperativaDescripcion} />,
    },
    {
        path: "/catalogos/TamanoHoja",
        element: <Page component={TamanoHoja} />,
    },
    {
        path: "/catalogos/ValorEcologico",
        element: <Page component={ValorEcologico} />,
    },
    {
        path: "/catalogos/ColorFollaje",
        element: <Page component={ColorFollaje} />,
    },
    {
        path: "/catalogos/CaracteristicasPatrimoniales",
        element: <Page component={CaracteristicasPatrimoniales} />,
    },
    {
        path: "/catalogos/CrecimientoAnual",
        element: <Page component={CrecimientoAnual} />,
    },
    {
        path: "/catalogos/CrecimientoCallo",
        element: <Page component={CrecimientoCallo} />,
    },
    {
        path: "/catalogos/EvaluacionBrotesEpicormicos",
        element: <Page component={EvaluacionBrotesEpicormicos} />,
    },
    {
        path: "/catalogos/EvaluacionRamasSecas",
        element: <Page component={EvaluacionRamasSecas} />,
    },
    {
        path: "/catalogos/CuerposFructiferos",
        element: <Page component={CuerposFructiferos} />,
    },
    {
        path: "/catalogos/EvaluacionDanosCopa",
        element: <Page component={EvaluacionDanosCopa} />,
    },
    {
        path: "/catalogos/DanoRadicular",
        element: <Page component={DanoRadicular} />,
    },
    {
        path: "/catalogos/EvaluacionDanoRadicular",
        element: <Page component={EvaluacionDanoRadicular} />,
    },
    {
        path: "/catalogos/EvaluacionAlteracionesRadiculares",
        element: <Page component={EvaluacionAlteracionesRadiculares} />,
    },
    {
        path: "/catalogos/DanosCopa",
        element: <Page component={DanosCopa} />,
    },
    {
        path: "/catalogos/EvaluacionAlteracionesCuelloRaiz",
        element: <Page component={EvaluacionAlteracionesCuelloRaiz} />,
    },
    {
        path: "/catalogos/DensidadCopa",
        element: <Page component={DensidadCopa} />,
    },
    {
        path: "/catalogos/EvaluacionAlteracionesTronco",
        element: <Page component={EvaluacionAlteracionesTronco} />,
    },
    {
        path: "/catalogos/EvaluacionAlteracionesInsercionRamaPrincipal",
        element: <Page component={EvaluacionAlteracionesInsercionRamaPrincipal} />,
    },
    {
        path: "/catalogos/DificultadOperativa",
        element: <Page component={DificultadOperativa} />,
    },
    {
        path: "/catalogos/EvaluacionAlteracionesRamasSecundarias",
        element: <Page component={EvaluacionAlteracionesRamasSecundarias} />,
    },
    {
        path: "/catalogos/DimensionHojas",
        element: <Page component={DimensionHojas} />,
    },
    {
        path: "/catalogos/EvaluacionCuerposFructiferos",
        element: <Page component={EvaluacionCuerposFructiferos} />,
    },
    {
        path: "/catalogos/FertilidadSuelo",
        element: <Page component={FertilidadSuelo} />,
    },
    {
        path: "/catalogos/EvaluacionCaracteristicasPatrimoniales",
        element: <Page component={EvaluacionCaracteristicasPatrimoniales} />,
    },
    {
        path: "/catalogos/Forma",
        element: <Page component={Forma} />,
    },
    {
        path: "/catalogos/Inclinacion",
        element: <Page component={Inclinacion} />,
    },
    {
        path: "/catalogos/CausaPatologiaIdentificacion",
        element: <Page component={CausaPatologiaIdentificacion} />,
    },
    {
        path: "/catalogos/RiesgoCaida",
        element: <Page component={RiesgoCaida} />,
    },
    {
        path: "/catalogos/Irrigacion",
        element: <Page component={Irrigacion} />,
    },
    {
        path: "/catalogos/SusceptibilidadArea",
        element: <Page component={SusceptibilidadArea} />,
    },
    {
        path: "/catalogos/ConcurrenciaArea",
        element: <Page component={ConcurrenciaArea} />,
    },
    {
        path: "/catalogos/PlanManejo",
        element: <Page component={PlanManejo} />,
    },
    {
        path: "/catalogos/ArbolPatrimonial",
        element: <Page component={ArbolPatrimonial} />,
    },
    {
        path: "/catalogos/ReporteGeneral",
        element: <Page component={ReporteGeneral} />,
    },
    {
        path: "/catalogos/FichaGeneralPdf",
        element: <Page component={FichaGeneralPdf} />,
    },
    {
        path: "/catalogos/UsuarioPerfil",
        element: <Page component={UsuarioPerfil} />,
    }
];
export default catalogosRoutes;
