import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';

const Logo = ({ mini, mode, sx }) => {
    return (
        <Div sx={{ display: "inline-flex", ...sx }}>
            <Link href={'/catalogos/ArbolPatrimonial'}>
                {
                    /* !mini ?
                        <img src={ mode === "light" ? `${ASSET_IMAGES}/logo.png` : `${ASSET_IMAGES}/logo-white.png`} alt="Jumbo React" />
                        :
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt="Jumbo React" /> */
                    <Div sx={{ display: "inline-flex", ...sx }}>
                        <ForestTwoToneIcon></ForestTwoToneIcon>
                        &nbsp;ARBOLES PATRIMONIALES
                    </Div>


                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
