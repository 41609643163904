import React from 'react';
import axios from 'axios';
import DificultadOperativaItem from '../../pages/catalogos/DificultadOperativaItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const DificultadOperativa = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [dificultadOperativas, setDificultadOperativas] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [nombre, setNombre] = React.useState(null);

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setNombre(refItem.nombre);
        }
    }, [open]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.nombre = nombre;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.dificultadOperativaId = 0;
            axios.post(`${API_URL}dificultadOperativa/create`, newItem, { headers }).then((response) => {
                setDificultadOperativas(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}dificultadOperativa/update`, newItem, { headers }).then((response) => {
                setDificultadOperativas(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}dificultadOperativa/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, { headers }).then((response) => {
            setDificultadOperativas(response.data);
            setTotalPages(response.data[0].totalPages);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    if (!dificultadOperativas) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Dificultad de intervención</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>}
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    title={
                        <Grid container spacing={1}>
                            <Grid item xs={7} md={7} lg={7}>
                                Nombre
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                Fecha mod.
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Usuario
                            </Grid>
                        </Grid>
                    }
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        dificultadOperativas.map((dificultadOperativa, key) => (<DificultadOperativaItem item={dificultadOperativa} setItems={setDificultadOperativas} key={dificultadOperativa.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={12} md={12} lg={12} >
                                            <TextField fullWidth required id='outlined-required' label='Nombre' defaultValue={refItem.nombre} inputProps={{ maxLength: 50 }} onChange={(event) => { setNombre(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default DificultadOperativa;
