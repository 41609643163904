import React from 'react';
import axios from 'axios';
import ArbolPatrimonialItem from '../../pages/catalogos/ArbolPatrimonialItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import EvaluacionCustom from './EvaluacionCustom';
import { UserPhotos } from "../../../app/pages/UserPhotos";
import DzPreviews from "../../pages/extensions/dropzone/components/DzPreviews";
import IntervencionItem from '../../pages/catalogos/IntervencionItem';
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import { Search, SearchIconWrapper, StyledInputBase } from "../../shared/SearchGlobal/style";
import { useNavigate } from "react-router-dom";
import { ASSET_IMAGES } from "../../../app/utils/constants/paths";
import ImageListItem from "@mui/material/ImageListItem";


const ArbolPatrimonial = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const formRef2 = React.useRef();
    const formRef3 = React.useRef();
    const [arbolPatrimonials, setArbolPatrimonials] = React.useState(null);
    const [arbolItem, setArbolItem] = React.useState({});
    const [sectors, setSectors] = React.useState(false);
    const [sector, setSector] = React.useState({});
    const [callSector, setCallSector] = React.useState(false);
    const [especies, setEspecies] = React.useState(false);
    const [especie, setEspecie] = React.useState({});
    const [callEspecie, setCallEspecie] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [openFotos, setOpenFotos] = React.useState(false);
    const [openFotoUnica, setOpenFotoUnica] = React.useState(false);
    const [fotoItem, setFotoItem] = React.useState({});
    const [openIntervencion, setOpenIntervencion] = React.useState(false);
    const [indexEvaluacion, setIndexEvaluacion] = React.useState(null);
    const [indexIntervencion, setIndexIntervencion] = React.useState(null);
    const [createMode, setCreateMode] = React.useState(true);
    const [createModeEvaluacion, setCreateModeEvaluacion] = React.useState(false);
    const [createModeIntervencion, setCreateModeIntervencion] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [createItemIntervencion, setCreateItemIntervencion] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogTitleIntervencion, setDialogTitleIntervencion] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [dialogButtonTextIntervencion, setDialogButtonTextIntervencion] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [individuo, setIndividuo] = React.useState(null);
    const [codigo, setCodigo] = React.useState(null);
    const [estaVivo, setEstaVivo] = React.useState(null);
    const [sectorId, setSectorId] = React.useState(null);
    const [especieId, setEspecieId] = React.useState(null);
    const [referencia, setReferencia] = React.useState(null);
    const [ubicacion, setUbicacion] = React.useState(null);
    const [nombreComun, setNombreComun] = React.useState(null);
    const [latitud, setLatitud] = React.useState(null);
    const [longitud, setLongitud] = React.useState(null);
    const [fotoAutor, setfotoAutor] = React.useState(null);
    const [fotoFecha, setfotoFecha] = React.useState(null);
    const [fotoOrden, setfotoOrden] = React.useState(null);
    const [openEvaluacion, setOpenEvaluacion] = React.useState(false);
    const [refItemEvaluacion, setRefItemEvaluacion] = React.useState({});
    const [refItemIntervencion, setRefItemIntervencion] = React.useState({});
    const [catalogs, setCatalogs] = React.useState({});
    const [evaluacionPlanManejoId, setEvaluacionPlanManejoId] = React.useState([]);
    const [interventor, setInterventor] = React.useState(null);
    const [fechaIntervencion, setFechaIntervencion] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);
    const [fotoArbolPatrimonialId, setFotoArbolPatrimonialId] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [disabledEvaluacion, setDisabledEvaluacion] = React.useState(false);
    const [disabledIntervencion, setDisabledIntervencion] = React.useState(false);
    const [disabledFotos, setDisabledFotos] = React.useState(false);
    const [updateFoto, setUpdateFoto] = React.useState(false);
    const [dataFotos, setDataFotos] = React.useState([]);

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    // Set headers
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };


    const catalogoEstaVivo = [{ nombre: 'Vivo', valor: true }, { nombre: 'Muerto', valor: false }];

    let formas = [];
    let posicions = [];
    let ultimaPodas = [];
    let valorEsteticos = [];
    let tipologiaLugars = [];
    let fertilidadSuelos = [];
    let suelos = [];
    let irrigacions = [];
    let dificultadOperativas = [];
    let dificultadOperativaDescripcions = [];
    let raizBajoPavimentos = [];
    let colorFollajes = [];
    let densidadCopas = [];
    let tamanoHojas = [];
    let crecimientoAnuals = [];
    let crecimientoCallos = [];
    let agenteDanos = [];
    let parasitosFungicoss = [];
    let inclinacions = [];
    let reduccionAparatoRadiculars = [];
    let cavidadess = [];
    let cavidadesDimensionMedias = [];
    let propensoRupturas = [];
    let intensidadUsoAreas = [];
    let brotesEpicormicos = [];
    let ramasSecas = [];
    let danosCopas = [];
    let danoRadiculars = [];
    let patologiaIdentificacions = [];
    let patologiaNivelDanos = [];
    let causaPatologiaIdentificacions = [];
    let causaPatologiaNivelAgenteDanos = [];
    let riesgoCaidas = [];
    let susceptibilidadAreas = [];
    let concurrenciaAreas = [];


    let alteracionesCuelloRaiz = [];
    let alteracionesInsercionRamaPrincipal = [];
    let alteracionesRadiculares = [];
    let alteracionesRamasSecundarias = [];
    let alteracionesTronco = [];
    let caracteristicasPatrimoniales = [];
    let cuerposFructiferos = [];
    let planManejos = [];
    let estadoLetreros = [];
    let valorEcologicos = [];
    let variedadEpifitas = [];


    const callEvaluadorCatalogs = () => {
        axios.post(`${API_URL}forma/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { formas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}posicion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { posicions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}ultimaPoda/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { ultimaPodas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}valorEstetico/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { valorEsteticos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}tipologiaLugar/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { tipologiaLugars = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}fertilidadSuelo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { fertilidadSuelos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}suelo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { suelos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}irrigacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { irrigacions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}dificultadOperativa/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { dificultadOperativas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}dificultadOperativaDescripcion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { dificultadOperativaDescripcions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}raizBajoPavimento/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { raizBajoPavimentos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}colorFollaje/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { colorFollajes = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}densidadCopa/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { densidadCopas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}tamanoHoja/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { tamanoHojas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}crecimientoAnual/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { crecimientoAnuals = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}crecimientoCallo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { crecimientoCallos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}agenteDano/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { agenteDanos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        //axios.post(`${API_URL}parasitosFungicos/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { parasitosFungicoss = response.data; });
        axios.post(`${API_URL}inclinacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { inclinacions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}reduccionAparatoRadicular/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { reduccionAparatoRadiculars = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        //axios.post(`${API_URL}cavidades/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { cavidadess = response.data; });
        //axios.post(`${API_URL}cavidadesDimensionMedia/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { cavidadesDimensionMedias = response.data; });
        //axios.post(`${API_URL}propensoRuptura/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { propensoRupturas = response.data; });

        //axios.post(`${API_URL}intensidadUsoArea/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { intensidadUsoAreas = response.data; });
        axios.post(`${API_URL}brotesEpicormicos/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { brotesEpicormicos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}ramasSecas/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { ramasSecas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}danosCopa/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { danosCopas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}danoRadicular/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { danoRadiculars = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}patologiaIdentificacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { patologiaIdentificacions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}patologiaNivelDano/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { patologiaNivelDanos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}causaPatologiaIdentificacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { causaPatologiaIdentificacions = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}causaPatologiaNivelAgenteDano/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { causaPatologiaNivelAgenteDanos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}riesgoCaida/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { riesgoCaidas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}susceptibilidadArea/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { susceptibilidadAreas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}concurrenciaArea/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { concurrenciaAreas = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });

        axios.post(`${API_URL}alteracionesCuelloRaiz/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { alteracionesCuelloRaiz = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}alteracionesInsercionRamaPrincipal/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { alteracionesInsercionRamaPrincipal = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}alteracionesRadiculares/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { alteracionesRadiculares = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}alteracionesRamasSecundarias/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { alteracionesRamasSecundarias = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}alteracionesTronco/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { alteracionesTronco = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}caracteristicasPatrimoniales/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { caracteristicasPatrimoniales = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}cuerposFructiferos/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { cuerposFructiferos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}planManejo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { planManejos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}estadoLetrero/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { estadoLetreros = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        axios.post(`${API_URL}valorEcologico/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => { valorEcologicos = response.data; }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        //axios.post(`${API_URL}variedadEpifitas/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }).then((response) => { variedadEpifitas = response.data; });
    };
    const getCatalogs = () => {
        return {
            formas: formas,
            posicions: posicions,
            ultimaPodas: ultimaPodas,
            valorEsteticos: valorEsteticos,
            tipologiaLugars: tipologiaLugars,
            fertilidadSuelos: fertilidadSuelos,
            suelos: suelos,
            irrigacions: irrigacions,
            dificultadOperativas: dificultadOperativas,
            dificultadOperativaDescripcions: dificultadOperativaDescripcions,
            raizBajoPavimentos: raizBajoPavimentos,
            colorFollajes: colorFollajes,
            densidadCopas: densidadCopas,
            tamanoHojas: tamanoHojas,
            crecimientoAnuals: crecimientoAnuals,
            crecimientoCallos: crecimientoCallos,
            agenteDanos: agenteDanos,
            parasitosFungicoss: parasitosFungicoss,
            inclinacions: inclinacions,
            reduccionAparatoRadiculars: reduccionAparatoRadiculars,
            cavidadess: cavidadess,
            cavidadesDimensionMedias: cavidadesDimensionMedias,
            propensoRupturas: propensoRupturas,
            intensidadUsoAreas: intensidadUsoAreas,
            brotesEpicormicos: brotesEpicormicos,
            ramasSecas: ramasSecas,
            danosCopas: danosCopas,
            danoRadiculars: danoRadiculars,
            patologiaIdentificacions: patologiaIdentificacions,
            patologiaNivelDanos: patologiaNivelDanos,
            causaPatologiaIdentificacions: causaPatologiaIdentificacions,
            causaPatologiaNivelAgenteDanos: causaPatologiaNivelAgenteDanos,
            riesgoCaidas: riesgoCaidas,
            susceptibilidadAreas: susceptibilidadAreas,
            concurrenciaAreas: concurrenciaAreas,
            alteracionesCuelloRaiz: alteracionesCuelloRaiz,
            alteracionesInsercionRamaPrincipal: alteracionesInsercionRamaPrincipal,
            alteracionesRadiculares: alteracionesRadiculares,
            alteracionesRamasSecundarias: alteracionesRamasSecundarias,
            alteracionesTronco: alteracionesTronco,
            caracteristicasPatrimoniales: caracteristicasPatrimoniales,
            cuerposFructiferos: cuerposFructiferos,
            planManejos: planManejos,
            estadoLetreros: estadoLetreros,
            valorEcologicos: valorEcologicos,
            variedadEpifitas: variedadEpifitas
        }
    };


    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    const commandAlertFoto = (variant, customText) => {
        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };


    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Creación de árbol patrimonial');
            setDialogButtonText('Insertar');
            setSector(null);
            setEspecie(null);
            setEstaVivo(catalogoEstaVivo.find((e) => { return e.valor === true }));
        }
        else {
            if (!disabled)
                setDialogTitle('Edición de árbol patrimonial');
            else
                setDialogTitle('Consulta de árbol patrimonial');
            setDialogButtonText('Actualizar');
            setIndividuo(refItem.individuo);
            setCodigo(refItem.codigo);
            setEstaVivo(catalogoEstaVivo.find((e) => { return e.valor === refItem.estaVivo }));
            setSectorId(refItem.sectorId);
            setEspecieId(refItem.especieId);
            setReferencia(refItem.referencia);
            setUbicacion(refItem.ubicacion);
            setNombreComun(refItem.nombreComun);
            setLatitud(refItem.latitud);
            setLongitud(refItem.longitud);
        }
    }, [open]);

    React.useEffect(() => {
        if (!sectors || !sectorId) {
            setSector(null);
            return;
        }
        setSector(sectors.find((e) => { return e.sectorId === sectorId }));
    }, [sectorId]);

    React.useEffect(() => {
        if (!arbolItem) {
            setArbolItem(null);
            return;
        }
        if (arbolItem.fotos)
            setDataFotos(arbolItem.fotos);
    }, [arbolItem]);



    React.useEffect(() => {
        if (!especies || !especieId) {
            setEspecie(null);
            return;
        }
        setEspecie(especies.find((e) => { return e.especieId === especieId }));
    }, [especieId]);


    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.individuo = individuo;
        newItem.codigo = codigo;
        newItem.estaVivo = estaVivo.valor;
        newItem.sectorId = sector.sectorId;
        newItem.especieId = especie.especieId;
        newItem.referencia = referencia;
        newItem.ubicacion = ubicacion;
        newItem.nombreComun = nombreComun;
        newItem.latitud = latitud;
        newItem.longitud = longitud;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.arbolPatrimonialId = 0;
            axios.post(`${API_URL}arbolPatrimonial/create`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}arbolPatrimonial/update`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);


    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        callEvaluadorCatalogs();
        axios.post(`${API_URL}arbolPatrimonial/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE, codigo: searchValue }, { headers }).then((response) => {
            // if (fotoArbolPatrimonialId)
            //     setArbolItem(response.data.find((e) => { return e.arbolPatrimonialId === fotoArbolPatrimonialId }));

            setArbolPatrimonials(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallSector(true);
            setCallEspecie(true);
            setCatalogs(getCatalogs());
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    React.useEffect(() => {
        if (!refresh || currentPage == undefined || currentPage === 0)
            return;
        setRefresh(false);
        callEvaluadorCatalogs();
        axios.post(`${API_URL}arbolPatrimonial/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE, codigo: searchValue }, { headers }).then((response) => {
            if (fotoArbolPatrimonialId)
                setArbolItem(response.data.find((e) => { return e.arbolPatrimonialId === fotoArbolPatrimonialId }));

            setArbolPatrimonials(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallSector(true);
            setCallEspecie(true);
            setCatalogs(getCatalogs());
        }).catch((err) => {
            if (err.message === "Cannot read properties of undefined (reading 'totalPages')")
                return;
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [refresh]);



    React.useEffect(() => {
        if (!callSector)
            return;
        axios.post(`${API_URL}sector/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setSectors(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callSector]);

    React.useEffect(() => {
        if (!callEspecie)
            return;
        axios.post(`${API_URL}especie/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setEspecies(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callEspecie]);

    React.useEffect(() => {
        if (!createItemIntervencion)
            return;
        const newItem = { intervencionPlanManejo: evaluacionPlanManejoId };
        newItem.evaluacionId = refItemEvaluacion.evaluacionId;
        newItem.interventor = interventor;
        newItem.fecha = fechaIntervencion;

        if (createModeIntervencion) {
            axios.post(`${API_URL}intervencion/create`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            newItem.intervencionId = refItemIntervencion.intervencionId;
            newItem.nombreCortoUsuario = "";
            newItem.usuarioId = sessionStorage.getItem('userId');
            axios.post(`${API_URL}intervencion/update`, newItem, { headers }).then((response) => {
                setRefresh(true);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItemIntervencion(false);
    }, [createItemIntervencion]);


    React.useEffect(() => {
        if (!updateFoto)
            return;
        const newItem = { fotoId: fotoItem.fotoId, arbolPatrimonialId: fotoItem.arbolPatrimonialId, path: fotoItem.path, orden: fotoOrden, autor: fotoAutor, fecha: fotoFecha };
        axios.post(`${API_URL}foto/update`, newItem, { headers }).then((response) => {
            setFotoArbolPatrimonialId(fotoItem.arbolPatrimonialId);
            setRefresh(true);
            commandAlert('success', 'actualizó', null);
            setOpenFotoUnica(false);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
        setUpdateFoto(false);
    }, [updateFoto]);


    React.useEffect(() => {
        if (!fotoItem || Object.keys(fotoItem).length === 0)
            return;

        setfotoAutor(fotoItem.autor);
        setfotoFecha(fotoItem.fecha);
        setfotoOrden(fotoItem.orden);
    }, [fotoItem]);

    React.useEffect(() => {
        if (!refItemEvaluacion.evaluacionId || !openIntervencion)
            return;
        if (createModeIntervencion) {
            setEvaluacionPlanManejoId([]);
            setInterventor(null);
            setFechaIntervencion(null);
            setDialogTitleIntervencion('Registro de tratamiento');
            setDialogButtonTextIntervencion('Insertar');
            axios.post(`${API_URL}intervencion/getLast`, { pageNumber: 1, rowsOfPage: 9999, evaluacionId: refItemEvaluacion.evaluacionId }, { headers }).then((response) => {
                setEvaluacionPlanManejoId(response.data.intervencionPlanManejos);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            setEvaluacionPlanManejoId([]);
            setInterventor(null);
            setFechaIntervencion(null);
            if (!disabledIntervencion)
                setDialogTitleIntervencion('Edición de tratamiento');
            else
                setDialogTitleIntervencion('Consulta de tratamiento');
            setDialogButtonTextIntervencion('Actualizar');
            axios.post(`${API_URL}intervencion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999, intervencionId: refItemIntervencion.intervencionId, evaluacionId: refItemIntervencion.evaluacionId }, { headers }).then((response) => {
                setFechaIntervencion(response.data.fecha);
                setInterventor(response.data.interventor);
                setEvaluacionPlanManejoId(response.data.intervencionPlanManejos);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }

    }, [openIntervencion]);

    if (!arbolPatrimonials) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Árboles patrimoniales</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>}
                <Search sx={{
                    maxWidth: { xs: 240, md: 320 }
                }}>
                    <Stack direction={'row'}>

                        <StyledInputBase
                            placeholder="Buscar por código..."
                            inputProps={{ 'aria-label': 'search' }}
                            defaultValue={searchValue}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    setRefresh(true);
                                }
                            }}
                            onChange={(event) => {
                                setSearchValue(event.target.value.trim());
                                if (event.target.value.trim() == "") {
                                    setSearchValue(null);
                                    setCurrentPage(1);
                                    setRefresh(true);
                                }

                            }}
                        />
                        <IconButton aria-label="search" size="small">
                            <SearchIcon onClick={() => { setRefresh(true); }} />
                        </IconButton>
                    </Stack>
                </Search>
            </Stack>
            <Stack>
                {
                    <EvaluacionCustom open={openEvaluacion} setOpen={setOpenEvaluacion} createMode={createModeEvaluacion} refItem={refItemEvaluacion} key={refItemEvaluacion.arbolPatrimonialId} catalogs={catalogs} arbolItem={arbolItem} indexEvaluacion={indexEvaluacion} setRefresh={setRefresh} disabledEvaluacion={disabledEvaluacion} />
                }
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        arbolPatrimonials.map((arbolPatrimonial, key) => (<ArbolPatrimonialItem item={arbolPatrimonial} setItems={setArbolPatrimonials} key={arbolPatrimonial.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} setOpenEvaluacion={setOpenEvaluacion} setRefItemEvaluacion={setRefItemEvaluacion} setCreateModeEvaluacion={setCreateModeEvaluacion} setArbolItem={setArbolItem} setOpenFotos={setOpenFotos} setIndexEvaluacion={setIndexEvaluacion} setOpenIntervencion={setOpenIntervencion} setRefItemIntervencion={setRefItemIntervencion} setIndexIntervencion={setIndexIntervencion} setCreateModeIntervencion={setCreateModeIntervencion} setRefresh={setRefresh} setDisabled={setDisabled} setDisabledEvaluacion={setDisabledEvaluacion} setDisabledIntervencion={setDisabledIntervencion} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => { setCurrentPage(value); }} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={2} md={2} lg={2} >
                                            <TextField disabled={disabled} fullWidth required id='outlined-required' label='Codigo' defaultValue={refItem.codigo} type='number' inputProps={{ maxLength: 10 }} onChange={(event) => { setCodigo(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={2} >
                                            <Autocomplete
                                                disabled={disabled}
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={catalogoEstaVivo}
                                                value={estaVivo}
                                                onChange={(event, newValue) => {
                                                    setEstaVivo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Condición" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disabled={disabled}
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={sectors}
                                                value={sector}
                                                onChange={(event, newValue) => {
                                                    setSector(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Sector" required />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disabled={disabled}
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={especies}
                                                value={especie}
                                                onChange={(event, newValue) => {
                                                    setEspecie(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Especie" required />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField disabled={disabled} fullWidth id='outlined' label='Referencia' defaultValue={refItem.referencia} inputProps={{ maxLength: 250 }} onChange={(event) => { setReferencia(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField disabled={disabled} fullWidth required id='outlined-required' label='Ubicacion' defaultValue={refItem.ubicacion} inputProps={{ maxLength: 250 }} onChange={(event) => { setUbicacion(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField disabled={disabled} fullWidth id='outlined' label='NombreComun' defaultValue={refItem.nombreComun} inputProps={{ maxLength: 100 }} onChange={(event) => { setNombreComun(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <TextField disabled={disabled} fullWidth required id='outlined-required' label='Latitud' defaultValue={refItem.latitud} type='number' inputProps={{ min: -180, max: 180, step: 0.0000000001 }} onChange={(event) => { setLatitud(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <TextField disabled={disabled} fullWidth required id='outlined-required' label='Longitud' defaultValue={refItem.longitud} type='number' inputProps={{ min: -180, max: 180, step: 0.0000000001 }} onChange={(event) => { setLongitud(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    {!disabled && <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>}
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth='true' maxWidth='xl' open={openFotos} onClose={() => setOpenFotos(false)}>
                    <DialogContent>
                        <Div>
                            <UserPhotos data={dataFotos} setRefresh={setRefresh} setFotoArbolPatrimonialId={setFotoArbolPatrimonialId} setOpenFotoUnica={setOpenFotoUnica} setFotoItem={setFotoItem} openFotoUnica={openFotoUnica} setDisabledFotos={setDisabledFotos} />
                            <Div>
                                <DzPreviews arbolPatrimonial={arbolItem} setRefresh={setRefresh} setFotoArbolPatrimonialId={setFotoArbolPatrimonialId} commandAlertFoto={commandAlertFoto} />
                            </Div>
                        </Div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenFotos(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth='true' maxWidth='xl' open={openFotoUnica} onClose={() => setOpenFotoUnica(false)}>
                    <DialogContent>
                        <Div>
                            <ImageListItem
                                key={fotoItem.path}
                                sx={{
                                    borderRadius: 2,
                                    overflow: 'hidden',

                                    '& .MuiImageListItemBar-root': {
                                        transition: 'all 0.3s ease',
                                        transform: 'translateY(100%)',
                                    },

                                    '&:hover .MuiImageListItemBar-root': {
                                        transform: 'translateY(0)',
                                    }
                                }}
                            >
                                <form ref={formRef3}>
                                    <Grid container spacing={3} p={2}>
                                        <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                            <TextField required="true" disabled={disabledFotos} fullWidth id='outlined' label='Autor:' defaultValue={fotoItem.autor} inputProps={{ maxLength: 100 }} onChange={(event) => { setfotoAutor(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                            <TextField required="true" disabled={disabledFotos} fullWidth id='outlined' label='Fecha' defaultValue={fotoItem.fecha ? fotoItem.fecha.slice(0, 10) : null} type='date' InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 10, format: "dd/mm/yyyy" }} onChange={(event) => { setfotoFecha(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                            <TextField disabled={disabledFotos} required="true" fullWidth id='outlined' label='Orden:' defaultValue={fotoItem.orden} inputProps={{ maxLength: 10 }} type='number' onChange={(event) => { setfotoOrden(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    {!disabledFotos && <Button sx={{ mb: 3 }} variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef3.current.reportValidity())
                                            return;
                                        setUpdateFoto(true);
                                    }} >
                                        Actualizar
                                    </Button>}
                                </form>
                                <img
                                    src={`${ASSET_IMAGES}/fotografias/${fotoItem.path}`}
                                    alt="image"
                                    loading="eager"
                                />
                            </ImageListItem>
                        </Div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenFotoUnica(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth='true' maxWidth='xl' open={openIntervencion} onClose={() => setOpenIntervencion(false)}>
                    <DialogContent>
                        <JumboCardQuick title={<Grid item xs={12} md={12} lg={12} textAlign={"center"} >
                            <Typography color="primary" variant={'h3'} >{dialogTitleIntervencion}</Typography>
                        </Grid>}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Grid container spacing={3} p={2}>
                                <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                    <Typography color="primary" variant={'h4'} >Arbol patrimonial: {refItemEvaluacion.arbolPatrimonialCodigo}</Typography>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                    <Typography color="primary" variant={'h4'} >Evaluación: {indexEvaluacion + 1}</Typography>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} textAlign={"center"} >
                                    <Typography color="primary" variant={'h4'} >Tratamiento: {indexIntervencion + 1}</Typography>
                                </Grid>
                            </Grid>
                            <Box p={2}>
                                <form ref={formRef2}>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={6} md={6} lg={6} >
                                            {(interventor || createModeIntervencion) && <TextField disabled={disabledIntervencion} fullWidth id='outlined' label='Responsable' required defaultValue={interventor} inputProps={{ maxLength: 100 }} onChange={(event) => { setInterventor(event.target.value); }} />}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} >
                                            {(fechaIntervencion || createModeIntervencion) && <TextField disabled={disabledIntervencion} fullWidth id='outlined' label='Fecha' required defaultValue={fechaIntervencion ? fechaIntervencion.slice(0, 10) : null} type='date' InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 10, format: "dd/mm/yyyy" }} onChange={(event) => { setFechaIntervencion(event.target.value); }} />}
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} >
                                            {
                                                evaluacionPlanManejoId && Array.isArray(evaluacionPlanManejoId) && evaluacionPlanManejoId.map((evaluacionPlanManejo, key) => (<IntervencionItem item={evaluacionPlanManejo} items={evaluacionPlanManejoId} setItems={setEvaluacionPlanManejoId} disabledIntervencion={disabledIntervencion} />))
                                            }
                                        </Grid>
                                    </Grid>
                                    {!disabledIntervencion && <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef2.current.reportValidity())
                                            return;
                                        setCreateItemIntervencion(true);
                                        setOpenIntervencion(false);
                                    }} >
                                        {dialogButtonTextIntervencion}
                                    </Button>}
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setInterventor(null); setFechaIntervencion(null); setOpenIntervencion(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default ArbolPatrimonial;
