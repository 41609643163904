import React from 'react';
import { Card, ListItem, Grid } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { styled } from '@mui/system';

const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
  `,
);

const EvaluacionPlanManejoItem = ({ item, items, setItems, disabledEvaluacion }) => {
    const Swal = useSwalWrapper();



    return (
        <Card sx={{ mb: 0.5 }}>
            <ListItem component={'li'} >
                <Grid container alignItems='center' justifyContent='center' spacing={3.75} sx={{ p: theme => theme.spacing(0.8, 1) }} >
                    <Grid item xs={3} md={3} lg={3}>
                        {item.planManejoNombre}
                    </Grid>
                    <Grid item xs={9} md={9} lg={9}>

                        <StyledTextarea
                            disabled={disabledEvaluacion}
                            label='Nota' defaultValue={item.nota} inputProps={{ maxLength: 3000 }}
                            onChange={(event) => {
                                var newItems = items;
                                for (let i = 0; i < newItems.length; i++) {
                                    if (newItems[i].planManejoId === item.planManejoId) {
                                        newItems[i].nota = event.target.value;
                                        setItems(newItems);
                                        return;
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </ListItem>
        </Card>
    );
};
export default EvaluacionPlanManejoItem;
