import React from 'react';
import axios from 'axios';
import { useDropzone } from "react-dropzone";
import { Button, Typography, TextField } from "@mui/material";
import DndWrapper from "./DndWrapper";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import SendIcon from '@mui/icons-material/Send';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { API_URL, ROWS_OF_PAGE } from '../../../../utils/constants/paths';
import LinearProgress from '@mui/material/LinearProgress';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const DzPreviews = ({ arbolPatrimonial, setRefresh, setFotoArbolPatrimonialId, commandAlertFoto }) => {

    const [files, setFiles] = React.useState([]);
    const [progressVisible, setProgressVisible] = React.useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        noDrag: true,
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));

    React.useEffect(
        () => () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files],
    );
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const processFile = () => {
        setProgressVisible(true);
        var bodyFormData = new FormData();
        if (!Array.isArray(files) || files.length == 0)
            return;
        //files.forEach(file => bodyFormData.append('images', file));
        for (let i = 0; i <= files.length - 1; i++) {
            let index = `images${i}`;
            //let index = `images`;
            //let id = "images" + Math.random().toString(16).slice(2);
            bodyFormData.append(index, files[i]);
        }

        axios.post(`${API_URL}foto/create`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Folder': arbolPatrimonial.codigo,
                'ArbolPatrimonialId': arbolPatrimonial.arbolPatrimonialId,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            setFotoArbolPatrimonialId(arbolPatrimonial.arbolPatrimonialId);
            commandAlertFoto('success', `Se cargó la imagen con exito!`);
            setRefresh(true);
            setFiles([]);
            setProgressVisible(false);
        }).catch((error) => {
            setProgressVisible(false);
            if (error.response) {
                //showError(error.response.data.message);
            }
        });
    };

    return (
        <div>
            {sessionStorage.getItem("perfil") !== "Lectura" &&
                <JumboCardQuick
                    title={"Zona de carga de archivos"}
                    wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}
                >
                    <Div sx={{ flex: 1 }}>
                        <DndWrapper>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Typography variant={"body1"}>Haga clic para escoger la ubicación de los archivos</Typography>
                            </div>
                        </DndWrapper>
                        <aside style={thumbsContainer}>{thumbs}</aside>
                    </Div>
                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => { processFile() }} >
                        Cargar
                    </Button>
                    {progressVisible &&
                        <Div sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Div>
                    }
                </JumboCardQuick>
            }
        </div>
    );
};

export default DzPreviews;
