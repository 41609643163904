import React from 'react';
import axios from 'axios';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionDetails, Card, Typography, Button, Grid } from "@mui/material";
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { ListItemIcon, ListItemButton, Fab } from '@mui/material';
import styled from "@emotion/styled";
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));


const ArbolPatrimonialItem = ({ item, setItems, currentPage, setTotalPages, commandAlert, setOpen, setRefItem, setCreateMode, setOpenEvaluacion, setRefItemEvaluacion, setCreateModeEvaluacion, setArbolItem, setOpenFotos, setIndexEvaluacion, setOpenIntervencion, setRefItemIntervencion, setIndexIntervencion, setCreateModeIntervencion, setRefresh, setDisabled, setDisabledEvaluacion, setDisabledIntervencion }) => {
    const [deleteItem, setDeleteItem] = React.useState(false);
    const [deleteItemIntervencion, setDeleteItemIntervencion] = React.useState(false);
    const [deleteEvaluacion, setDeleteEvaluacion] = React.useState(false);
    const [itemEvaluacion, setItemEvaluacion] = React.useState({});
    const [itemIntervencion, setItemIntervencion] = React.useState({});

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const [expanded, setExpanded] = React.useState(false);

    const handleClick = () => {
        setExpanded(!expanded);
    }

    const handlePdf = (evaluacionId) => {
        axios.post(`${API_URL}evaluacion/pdfReport`, { evaluacionId: evaluacionId }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }, responseType: "blob" }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            const filename = "ficha.pdf";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }

    const confirmDelete = (keyItem) => { Swal.fire({ title: `¿Está seguro de eliminar: ${keyItem}?`, text: 'No se podrá revertir esta acción!', icon: 'warning', showCancelButton: true, confirmButtonText: 'Si, eliminar!', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => { if (result.value) { setDeleteItem(true); } else if (result.dismiss === Swal.DismissReason.cancel) { } }); };
    const confirmDeleteEvaluacion = (keyItem) => { Swal.fire({ title: `¿Está seguro de eliminar: ${keyItem}?`, text: 'No se podrá revertir esta acción!', icon: 'warning', showCancelButton: true, confirmButtonText: 'Si, eliminar!', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => { if (result.value) { setDeleteEvaluacion(true); } else if (result.dismiss === Swal.DismissReason.cancel) { } }); };
    const confirmDeleteIntervencion = (keyItem) => { Swal.fire({ title: `¿Está seguro de eliminar: ${keyItem}?`, text: 'No se podrá revertir esta acción!', icon: 'warning', showCancelButton: true, confirmButtonText: 'Si, eliminar!', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => { if (result.value) { setDeleteItemIntervencion(true); } else if (result.dismiss === Swal.DismissReason.cancel) { } }); };
    React.useEffect(() => {
        if (!deleteItem)
            return;
        let toDeleteItem = item;
        toDeleteItem.rowsOfPage = ROWS_OF_PAGE;
        toDeleteItem.pageNumber = currentPage;
        axios.post(`${API_URL}arbolPatrimonial/delete`, toDeleteItem, { headers }).then((response) => {
            commandAlert('success', 'eliminó', null);
            setRefresh(true);
            setDeleteItem(false);
        }).catch((error) => {
            if (error.response) {
                commandAlert('error', '', error.response.data);
            }
            setDeleteItem(false);
        });
    }, [deleteItem]);

    React.useEffect(() => {
        if (!deleteItemIntervencion)
            return;
        let toDeleteItem = itemIntervencion;
        toDeleteItem.rowsOfPage = 9999;
        toDeleteItem.pageNumber = currentPage;
        axios.post(`${API_URL}intervencion/delete`, toDeleteItem, { headers }).then((response) => {
            setRefresh(true);
            commandAlert('success', 'eliminó', null);
            setDeleteItemIntervencion(false);
        }).catch((error) => {
            if (error.response) {
                commandAlert('error', '', error.response.data);
            }
            setDeleteItemIntervencion(false);
        });
    }, [deleteItemIntervencion]);

    React.useEffect(() => {
        if (!deleteEvaluacion)
            return;
        let toDeleteItem = itemEvaluacion;
        toDeleteItem.pageNumber = 1;
        toDeleteItem.rowsOfPage = 9999;
        axios.post(`${API_URL}evaluacion/delete`, toDeleteItem, { headers }).then((response) => {
            setRefresh(true);
            commandAlert('success', 'eliminó', null);
            setDeleteEvaluacion(false);
        }).catch((error) => {
            if (error.response) {
                commandAlert('error', '', error.response.data);
            }
            setDeleteEvaluacion(false);
        });
    }, [deleteEvaluacion]);

    if (!item)
        return null;
    return (
        <Card sx={{ mb: 1 }}>
            <ListItemButton component={'li'} sx={{ p: theme => theme.spacing(0, 0), '&:hover .ListItemIcons': { opacity: 1 } }}>
                <Accordion expanded={expanded} onChange={() => { handleClick() }} square sx={{ borderRadius: 2, width: '100%' }}>
                    <AccordionSummary
                        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                    margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Div sx={{ flexShrink: 0, px: 1 }}>
                            <ParkOutlinedIcon></ParkOutlinedIcon>
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '7%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            <Typography variant={"h5"} mb={.5} fontSize={14}>{item.codigo}</Typography>
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '6%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            {item.estaVivo && <Chip color={"success"} size={"small"} label="VIVO" />}
                            {!item.estaVivo && <Chip color={"secondary"} size={"small"} label="MUERTO" />}
                        </Div>
                        <Div
                            sx={{
                                width: { xs: 'auto', lg: '25%' },
                                flexShrink: 0,
                                px: 1,
                                flex: { xs: '1', lg: '0 1 auto' },
                            }}
                        >
                            <Typography variant={"h5"} mb={.5} fontSize={14}>{item.especieNombre}</Typography>
                            <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>

                                {item.nombreComun}
                            </Typography>
                        </Div>
                        <Div sx={{ display: { xs: 'none', lg: 'block' }, width: '31%', flexShrink: 0, px: 1 }}>

                            <Typography variant={"body1"}>{item.sectorNombre}</Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                {item.ubicacion}
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                {item.referencia}
                            </Typography>
                        </Div>
                        <Div sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                Latitud
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                {item.latitud}
                            </Typography>

                        </Div>
                        <Div sx={{ flex: { xs: '0 1 auto', lg: 1 }, flexShrink: 0, px: 1 }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                Longitud
                            </Typography>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                {item.longitud}
                            </Typography>
                        </Div>

                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(0, 0, 2, 2) }}>
                        <Div>
                            {
                                sessionStorage.getItem("perfil") !== "Lectura" &&
                                <IconButton color={"info"} aria-label="print" size="large" onClick={() => { setOpenEvaluacion(true); setRefItemEvaluacion({}); setCreateModeEvaluacion(true); setArbolItem(item); }}>
                                    <AddCircleIcon fontSize="inherit" />
                                    <Typography mt={1} ml={1} variant={'h5'} >EVALUACIÓN</Typography>
                                </IconButton>
                            }

                            {
                                item.evaluacions && item.evaluacions.map((evaluacion, index) => (
                                    <div>
                                        <Card sx={{ border: 1, mb: 3, borderColor: 'divider', backgroundColor: '#f9f6ff', p: theme => theme.spacing(2, 2, 2, 2) }}>
                                            <Grid container spacing={3} p={1} >
                                                <Grid item xs={4} md={4} lg={4} >
                                                    <Typography ml={2} variant={'h6'} >EVALUACIÓN {index + 1}</Typography>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12} lg={12} >


                                                            <IconButton color={"info"} aria-label="edit" size="medium">
                                                                <VisibilityIcon fontSize="inherit" onClick={() => { setOpenEvaluacion(true); setCreateModeEvaluacion(false); setRefItemEvaluacion(evaluacion); setIndexEvaluacion(index); setDisabledEvaluacion(true); }} />
                                                            </IconButton>
                                                            {sessionStorage.getItem("perfil") !== "Lectura" &&

                                                                <IconButton color={"info"} aria-label="edit" size="medium">
                                                                    <EditIcon fontSize="inherit" onClick={() => { setOpenEvaluacion(true); setCreateModeEvaluacion(false); setRefItemEvaluacion(evaluacion); setIndexEvaluacion(index); setDisabledEvaluacion(false); }} />
                                                                </IconButton>}
                                                            {sessionStorage.getItem("perfil") !== "Lectura" &&
                                                                <IconButton color={"info"} aria-label="print" size="medium">
                                                                    <PrintIcon fontSize="inherit" onClick={() => { handlePdf(evaluacion.evaluacionId); }} />
                                                                </IconButton>
                                                            }
                                                            {sessionStorage.getItem("perfil") !== "Lectura" &&
                                                                <IconButton color={"info"} aria-label="delete" size="medium">
                                                                    <DeleteIcon fontSize="inherit" onClick={() => { setItemEvaluacion(evaluacion); confirmDeleteEvaluacion("Evaluación " + (index + 1)); }} />
                                                                </IconButton>
                                                            }



                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4} md={3} lg={3} >
                                                    <Typography color={"text.secondary"}> Evaluador: </Typography>
                                                    {evaluacion.evaluador}
                                                </Grid>
                                                <Grid item xs={4} md={5} lg={5} >
                                                    <Typography color={"text.secondary"}> Fecha: </Typography>
                                                    {evaluacion.fecha}
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3} pb={3} pt={1} >
                                                <Grid item xs={12} md={12} lg={12} >
                                                    {
                                                        sessionStorage.getItem("perfil") !== "Lectura" &&
                                                        <IconButton aria-label="print" color='primary' size="large" onClick={() => {
                                                            setIndexEvaluacion(index);
                                                            setRefItemEvaluacion(evaluacion);
                                                            setCreateModeIntervencion(true);
                                                            setOpenIntervencion(true);
                                                        }}>
                                                            <AddCircleIcon fontSize="inherit" /> <Typography mt={1} ml={1} variant={'h6'} >TRATAMIENTO</Typography>
                                                        </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>
                                            {
                                                evaluacion.intervencions && evaluacion.intervencions.map((intervencion, index) =>
                                                (
                                                    <Grid container sx={{ borderTop: 1, backgroundColor: 'white', borderColor: 'divider', p: theme => theme.spacing(1, 1, 1, 1) }} >
                                                        <Grid item xs={4} md={3} lg={3} >
                                                            <Typography variant={'h6'} sx={{ pt: 1.3 }}  >Tratamiento {index + 1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} md={3} lg={3} >
                                                            <Typography color={"text.secondary"}> Responsable: </Typography>
                                                            {intervencion.interventor}
                                                        </Grid>
                                                        <Grid item xs={4} md={2} lg={2} >
                                                            <Typography color={"text.secondary"}> Fecha: </Typography>
                                                            Fecha: {intervencion.fecha}
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4} >
                                                            <IconButton aria-label="edit" size="small">
                                                                <VisibilityIcon fontSize="inherit" onClick={() => {
                                                                    setRefItemIntervencion(intervencion);
                                                                    setIndexIntervencion(index);
                                                                    setRefItemEvaluacion(evaluacion);
                                                                    setCreateModeIntervencion(false);
                                                                    setOpenIntervencion(true);
                                                                    setDisabledIntervencion(true);
                                                                }} />

                                                            </IconButton>
                                                            {sessionStorage.getItem("perfil") !== "Lectura" &&
                                                                <IconButton aria-label="edit" size="small">
                                                                    <EditIcon fontSize="inherit" onClick={() => {
                                                                        setRefItemIntervencion(intervencion);
                                                                        setIndexIntervencion(index);
                                                                        setRefItemEvaluacion(evaluacion);
                                                                        setCreateModeIntervencion(false);
                                                                        setOpenIntervencion(true);
                                                                        setDisabledIntervencion(false);
                                                                    }} />

                                                                </IconButton>
                                                            }
                                                            {sessionStorage.getItem("perfil") !== "Lectura" &&
                                                                <IconButton aria-label="delete" size="small">
                                                                    <DeleteIcon fontSize="inherit" onClick={() => { setItemIntervencion(intervencion); confirmDeleteIntervencion("Tratamiento " + (index + 1)); }} />
                                                                </IconButton>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Card>
                                    </div>
                                ))
                            }
                        </Div>
                    </AccordionDetails>
                </Accordion>
                <ListItemIcon className={'ListItemIcons'} sx={{ position: 'absolute', right: 6, top: 7, transition: 'all 0.2s', opacity: 0 }}>
                    <Fab onClick={() => { setRefItem(item); setCreateMode(false); setOpen(true); setDisabled(true); }} size='small' sx={{ right: 8 }}><VisibilityIcon /></Fab>
                    <Fab onClick={() => { setArbolItem(item); setOpenFotos(true); }} size='small' color={"success"} sx={{ right: 3 }}><InsertPhotoIcon /></Fab>
                    {sessionStorage.getItem("perfil") !== "Lectura" && <Fab onClick={() => { setRefItem(item); setCreateMode(false); setOpen(true); setDisabled(false); }} size='small' color={'primary'}><EditIcon /></Fab>}
                    {sessionStorage.getItem("perfil") !== "Lectura" && <Fab onClick={() => confirmDelete(item.nombreComun)} size='small' sx={{ left: 4 }} color={'secondary'}><DeleteIcon /></Fab>}
                </ListItemIcon>
            </ListItemButton>
        </Card >
    );
};
export default ArbolPatrimonialItem;