import React from 'react';
import axios from 'axios';
import UsuarioItem from '../../pages/catalogos/UsuarioItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const Usuario = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [usuarios, setUsuarios] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [userName, setUserName] = React.useState(null);
    const [nombreCompleto, setNombreCompleto] = React.useState(null);
    const [nombreCorto, setNombreCorto] = React.useState(null);
    const [habilitado, setHabilitado] = React.useState(null);

    const catalogoHabilitado = [{ nombre: 'Habilitado', valor: true }, { nombre: 'Deshabilitado', valor: false }];
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };
    const navigate = useNavigate();

    if (!localStorage.getItem('token') || sessionStorage.getItem("perfil") !== "Administrador")
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
            setHabilitado(catalogoHabilitado.find((e) => { return e.valor === refItem.habilitado }));
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setUserName(refItem.userName);
            setNombreCompleto(refItem.nombreCompleto);
            setNombreCorto(refItem.nombreCorto);
            setHabilitado(catalogoHabilitado.find((e) => { return e.valor === refItem.habilitado }));
        }
    }, [open]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.email = userName;
        newItem.userName = userName;
        newItem.nombreCompleto = nombreCompleto;
        newItem.nombreCorto = nombreCorto;
        newItem.habilitado = habilitado.valor;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.usuarioId = 0;
            axios.post(`${API_URL}usuario/create`, newItem, { headers }).then((response) => {
                setUsuarios(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response) {
                    commandAlert('error', '', err.response.data);
                    return;
                }
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}usuario/update`, newItem, { headers }).then((response) => {
                setUsuarios(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}usuario/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, { headers }).then((response) => {
            setUsuarios(response.data);
            setTotalPages(response.data[0].totalPages);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    if (!usuarios) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Usuario</Typography>
                <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    title={
                        <Grid container spacing={1}>
                            <Grid item xs={2} md={2} lg={2}>
                                Username
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Nombre completo
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Nombre corto
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Habilitado
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Fecha Mod.
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Usuario
                            </Grid>
                        </Grid>
                    }
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        usuarios.map((usuario, key) => (<UsuarioItem item={usuario} setItems={setUsuarios} key={usuario.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={6} md={6} lg={6} >
                                            <TextField fullWidth disabled={!createMode} required id='outlined-required' label='Username' type="email" defaultValue={refItem.userName} inputProps={{ maxLength: 50 }} onChange={(event) => { setUserName(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth required id='outlined-required' label='NombreCompleto' defaultValue={refItem.nombreCompleto} inputProps={{ maxLength: 100 }} onChange={(event) => { setNombreCompleto(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth required id='outlined-required' label='NombreCorto' defaultValue={refItem.nombreCorto} inputProps={{ maxLength: 50 }} onChange={(event) => { setNombreCorto(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={catalogoHabilitado}
                                                value={habilitado}
                                                onChange={(event, newValue) => {
                                                    setHabilitado(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Habilitado" required />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default Usuario;
