import React from 'react';
import axios from 'axios';
import { Typography, Stack, Fab } from '@mui/material';
import { API_URL } from '../../utils/constants/paths';
import LinearProgress from '@mui/material/LinearProgress';
import Div from "@jumbo/shared/Div";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useNavigate } from "react-router-dom";

const ReporteGeneral = () => {
    const [open, setOpen] = React.useState(false);
    const [progressVisible, setProgressVisible] = React.useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!open)
            return;
        setOpen(false);
        setProgressVisible(true);
        axios.post(`${API_URL}arbolPatrimonial/generalReport`, { reporte: "generalReport" }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }, responseType: "blob" }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            const filename = "reporteGeneral.xlsx";
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setProgressVisible(false);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [open]);


    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Reporte general</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { setOpen(true); }} color={'primary'}><AssessmentIcon /></Fab>}
            </Stack>
            {progressVisible &&
                <Div sx={{ width: '100%' }}>
                    <LinearProgress />
                </Div>
            }
        </React.Fragment>
    );
};
export default ReporteGeneral;
