import React from 'react';
import axios from 'axios';
import EvaluacionAlteracionesRamasSecundariasItem from '../../pages/catalogos/EvaluacionAlteracionesRamasSecundariasItem';
import SendIcon from '@mui/icons-material/Send';
import {Typography,Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useTranslation} from 'react-i18next';
import {API_URL,ROWS_OF_PAGE} from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const EvaluacionAlteracionesRamasSecundarias = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [evaluacionAlteracionesRamasSecundariass, setEvaluacionAlteracionesRamasSecundariass] = React.useState(null);
    const [evaluacions, setEvaluacions] = React.useState(false);
    const [evaluacion, setEvaluacion] = React.useState({ });
    const [callEvaluacion, setCallEvaluacion] = React.useState(false);
    const [alteracionesRamasSecundariass, setAlteracionesRamasSecundariass] = React.useState(false);
    const [alteracionesRamasSecundarias, setAlteracionesRamasSecundarias] = React.useState({ });
    const [callAlteracionesRamasSecundarias, setCallAlteracionesRamasSecundarias] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [evaluacionId, setEvaluacionId] = React.useState(null);
    const [alteracionesRamasSecundariasId, setAlteracionesRamasSecundariasId] = React.useState(null);
    const [nota, setNota] = React.useState(null);
    
    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer);}});
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper});
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
            setEvaluacion(null);
            setAlteracionesRamasSecundarias(null);
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setEvaluacionId(refItem.evaluacionId);
            setAlteracionesRamasSecundariasId(refItem.alteracionesRamasSecundariasId);
            setNota(refItem.nota);
        }
    }, [open]);

    React.useEffect(() => {
        if (!evaluacions || !evaluacionId)
        {
            setEvaluacion(null);
            return;
        }
        setEvaluacion(evaluacions.find((e) => { return e.evaluacionId === evaluacionId}));
    }, [evaluacionId]);

    React.useEffect(() => {
        if (!alteracionesRamasSecundariass || !alteracionesRamasSecundariasId)
        {
            setAlteracionesRamasSecundarias(null);
            return;
        }
        setAlteracionesRamasSecundarias(alteracionesRamasSecundariass.find((e) => { return e.alteracionesRamasSecundariasId === alteracionesRamasSecundariasId}));
    }, [alteracionesRamasSecundariasId]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.evaluacionId = evaluacion.evaluacionId;
        newItem.alteracionesRamasSecundariasId = alteracionesRamasSecundarias.alteracionesRamasSecundariasId;
        newItem.nota = nota;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.evaluacionAlteracionesRamasSecundariasId = 0;
            axios.post(`${API_URL}evaluacionAlteracionesRamasSecundarias/create`, newItem, {headers}).then((response) => {
                setEvaluacionAlteracionesRamasSecundariass(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login"); 
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}evaluacionAlteracionesRamasSecundarias/update`, newItem, {headers}).then((response) => {
                setEvaluacionAlteracionesRamasSecundariass(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login"); 
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}evaluacionAlteracionesRamasSecundarias/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, {headers}).then((response) => {
            setEvaluacionAlteracionesRamasSecundariass(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallEvaluacion(true);
            setCallAlteracionesRamasSecundarias(true);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    React.useEffect(() => {
        if (!callEvaluacion)
            return;
        axios.post(`${API_URL}evaluacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, {headers}).then((response) => {
            setEvaluacions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callEvaluacion]);

    React.useEffect(() => {
        if (!callAlteracionesRamasSecundarias)
            return;
        axios.post(`${API_URL}alteracionesRamasSecundarias/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, {headers}).then((response) => {
            setAlteracionesRamasSecundariass(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callAlteracionesRamasSecundarias]);

    if (!evaluacionAlteracionesRamasSecundariass) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>EvaluacionAlteracionesRamasSecundarias</Typography>
                <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    title={
                        <Grid container spacing={1}>
                            <Grid item xs={3} md={3} lg={3}>
                                 EvaluacionId
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                 Nombre
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                 Nota
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Fecha mod.
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                Usuario
                            </Grid>
                        </Grid>
                    }
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        evaluacionAlteracionesRamasSecundariass.map((evaluacionAlteracionesRamasSecundarias, key) => (<EvaluacionAlteracionesRamasSecundariasItem item={evaluacionAlteracionesRamasSecundarias} setItems={setEvaluacionAlteracionesRamasSecundariass} key={evaluacionAlteracionesRamasSecundarias.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                               <form ref={formRef}>
                                  <Grid container spacing={3} p={1} pb={3}>
                                     <Grid item xs={4} md={4} lg={4} >
                                         <Autocomplete
                                             required
                                             disablePortal
                                             id="combo-box-demo"
                                             getOptionLabel={(o) => o.evaluacionId}
                                             options={evaluacions}
                                             value={evaluacion}
                                             onChange={(event, newValue) => {
                                                 setEvaluacion(newValue);
                                             }}
                                             renderInput={(params) => <TextField {...params} label="Evaluacion" required />}
                                         />
                                      </Grid>
                                     <Grid item xs={4} md={4} lg={4} >
                                         <Autocomplete
                                             required
                                             disablePortal
                                             id="combo-box-demo"
                                             getOptionLabel={(o) => o.nombre}
                                             options={alteracionesRamasSecundariass}
                                             value={alteracionesRamasSecundarias}
                                             onChange={(event, newValue) => {
                                                 setAlteracionesRamasSecundarias(newValue);
                                             }}
                                             renderInput={(params) => <TextField {...params} label="AlteracionesRamasSecundarias" required />}
                                         />
                                      </Grid>
                                     <Grid item xs={4} md={4} lg={4} >
                                         <TextField fullWidth id='outlined' label='Nota' defaultValue={refItem.nota} inputProps={{ maxLength: 100 }} onChange={(event) => { setNota(event.target.value);}} />
                                      </Grid>
                                  </Grid>
                                   <Button variant='contained' endIcon={<SendIcon />} onClick={() => { 
                                       if (!formRef.current.reportValidity())
                                           return;
                                       setCreateItem(true); 
                                       setOpen(false); }} >
                                       {dialogButtonText}
                                  </Button>
                              </form>
                          </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default EvaluacionAlteracionesRamasSecundarias;
