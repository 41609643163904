import React from 'react';
import axios from 'axios';
import { ImageListItemBar, useTheme, createTheme } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { ASSET_IMAGES } from "../../../app/utils/constants/paths";
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';


const PictureItem2 = ({ item, setRefresh, setFotoArbolPatrimonialId, setOpenFotoUnica, setFotoItem, openFotoUnica, setDisabledFotos }) => {
    const theme2 = createTheme({
        typography: {
            subtitle1: {
                fontSize: '0.8rem'
            },
            subtitle2: {
                fontSize: '0.8rem',
                fontWeight: 1000,
            },
            subtitle3: {
                fontSize: '0.72rem'
            }

        },
    });
    const theme = useTheme();
    const [deleteItem, setDeleteItem] = React.useState(false);
    const Swal = useSwalWrapper();
    const confirmDelete = (keyItem) => { Swal.fire({ title: `¿Está seguro de eliminar: ${keyItem}?`, text: 'No se podrá revertir esta acción!', icon: 'warning', showCancelButton: true, confirmButtonText: 'Si, eliminar!', cancelButtonText: 'No, cancelar!', reverseButtons: true }).then(result => { if (result.value) { setDeleteItem(true); } else if (result.dismiss === Swal.DismissReason.cancel) { } }); };
    const download = (path) => {
        let extension = path.split('.');
        fetch(`${ASSET_IMAGES}/fotografias/${path}`, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `image.${extension[1]}`);
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }


    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    React.useEffect(() => {
        if (!deleteItem)
            return;
        let toDeleteItem = item;
        toDeleteItem.fotoId = item.fotoId;
        axios.post(`${API_URL}foto/delete`, toDeleteItem, { headers }).then((response) => {
            commandAlert('success', 'eliminó', null);
            setFotoArbolPatrimonialId(item.arbolPatrimonialId);
            setRefresh(true);
            setDeleteItem(false);
        }).catch((error) => {
            if (error.response) {
                commandAlert('error', '', error.response.data);
            }
            setDeleteItem(false);
        });
    }, [deleteItem]);

    return (
        <ImageListItem
            key={item.img}
            sx={{
                borderRadius: 2,
                overflow: 'hidden',
                minHeight: '120px',

                '& .MuiImageListItemBar-root': {
                    transition: 'all 0.3s ease',
                    transform: 'translateY(100%)',
                },

                '&:hover .MuiImageListItemBar-root': {
                    transform: 'translateY(0)',
                }
            }}
        >

            <img
                src={`${ASSET_IMAGES}/fotografias/${item.path}?w=248&fit=crop&auto=format`}
                alt={item.codigo}
                loading="eager"
            />

            {!openFotoUnica && < ImageListItemBar
                // title={
                //     <Div>
                //         {item.codigo}
                //     </Div>
                // }
                subtitle={
                    <Div>
                        <Div>
                            <Typography theme={theme2} variant='subtitle2'>{item.codigo}</Typography>
                            <Typography theme={theme2} variant='subtitle1'>{item.fecha ? item.fecha.toString().substring(0, 10) : ""}</Typography>
                            <Typography theme={theme2} variant='subtitle3'>{item.autor}</Typography>
                            <div>&nbsp;</div>
                        </Div>
                        <IconButton aria-label="delete" size="small" sx={
                            {
                                bgcolor: '#00a8ff',
                                color: 'common.white',
                                p: theme => theme.spacing(1.25),

                                '&:hover': {
                                    backgroundColor: '#00a8ff',
                                }
                            }
                        }>
                            <VisibilityIcon fontSize="inherit" onClick={() => { setFotoItem(item); setDisabledFotos(true); setOpenFotoUnica(true); }} />
                        </IconButton>
                        &nbsp;
                        {sessionStorage.getItem("perfil") !== "Lectura" &&
                            <IconButton aria-label="delete" size="small" sx={
                                {
                                    bgcolor: '#00a8ff',
                                    color: 'common.white',
                                    p: theme => theme.spacing(1.25),

                                    '&:hover': {
                                        backgroundColor: '#00a8ff',
                                    }
                                }
                            }>
                                <DownloadIcon fontSize="inherit" onClick={() => { download(item.path) }} />
                            </IconButton>
                        }
                        &nbsp;
                        {sessionStorage.getItem("perfil") !== "Lectura" &&
                            <IconButton aria-label="delete" size="small" sx={
                                {
                                    bgcolor: '#00a8ff',
                                    color: 'common.white',
                                    p: theme => theme.spacing(1.25),

                                    '&:hover': {
                                        backgroundColor: '#00a8ff',
                                    }
                                }
                            }>
                                <EditIcon fontSize="inherit" onClick={() => { setFotoItem(item); setDisabledFotos(false); setOpenFotoUnica(true); }} />
                            </IconButton>
                        }
                        &nbsp;
                        {sessionStorage.getItem("perfil") !== "Lectura" &&
                            <IconButton aria-label="delete" size="small" sx={
                                {
                                    bgcolor: '#00a8ff',
                                    color: 'common.white',
                                    p: theme => theme.spacing(1.25),

                                    '&:hover': {
                                        backgroundColor: '#00a8ff',
                                    }
                                }
                            }>
                                <DeleteIcon fontSize="inherit" onClick={() => { confirmDelete(item.codigo) }} />
                            </IconButton>
                        }
                    </Div>
                }
            />}
        </ImageListItem>
    );
};
/* Todo item prop define */
export default PictureItem2;
