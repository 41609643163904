import React from 'react';
import axios from 'axios';
import UsuarioPerfilItem from '../../pages/catalogos/UsuarioPerfilItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const UsuarioPerfil = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [usuarioPerfils, setUsuarioPerfils] = React.useState(null);
    const [usuarios, setUsuarios] = React.useState(false);
    const [usuario, setUsuario] = React.useState({});
    const [callUsuario, setCallUsuario] = React.useState(false);
    const [perfils, setPerfils] = React.useState(false);
    const [perfil, setPerfil] = React.useState({});
    const [callPerfil, setCallPerfil] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [usuarioId, setUsuarioId] = React.useState(null);
    const [perfilId, setPerfilId] = React.useState(null);


    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };
    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");
    if (sessionStorage.getItem("perfil") !== "Administrador")
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
            setUsuario(null);
            setPerfil(null);
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setUsuarioId(refItem.usuarioId);
            setPerfilId(refItem.perfilId);
        }
    }, [open]);

    React.useEffect(() => {
        if (!usuarios || !usuarioId) {
            setUsuario(null);
            return;
        }
        setUsuario(usuarios.find((e) => { return e.usuarioId === usuarioId }));
    }, [usuarioId]);

    React.useEffect(() => {
        if (!perfils || !perfilId) {
            setPerfil(null);
            return;
        }
        setPerfil(perfils.find((e) => { return e.perfilId === perfilId }));
    }, [perfilId]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.usuarioId = usuario.usuarioId;
        newItem.perfilId = perfil.perfilId;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.usuarioPerfilId = 0;
            axios.post(`${API_URL}usuarioPerfil/create`, newItem, { headers }).then((response) => {
                setUsuarioPerfils(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response) {
                    commandAlert('error', '', err.response.data);
                    return;
                }
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });;
        }
        else {
            axios.post(`${API_URL}usuarioPerfil/update`, newItem, { headers }).then((response) => {
                setUsuarioPerfils(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });;
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}usuarioPerfil/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, { headers }).then((response) => {
            setUsuarioPerfils(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallUsuario(true);
            setCallPerfil(true);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });;
    }, [currentPage]);

    React.useEffect(() => {
        if (!callUsuario)
            return;
        axios.post(`${API_URL}usuario/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setUsuarios(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });;
    }, [callUsuario]);

    React.useEffect(() => {
        if (!callPerfil)
            return;
        axios.post(`${API_URL}perfil/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setPerfils(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });;
    }, [callPerfil]);

    if (!usuarioPerfils) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Perfil</Typography>
                <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    title={
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={4} lg={4}>
                                NombreCompleto
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                Nombre
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                Fecha mod.
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Usuario
                            </Grid>
                        </Grid>
                    }
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        usuarioPerfils.map((usuarioPerfil, key) => (<UsuarioPerfilItem item={usuarioPerfil} setItems={setUsuarioPerfils} key={usuarioPerfil.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent >
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }} sx={{ minHeight: "350px" }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombreCompleto}
                                                options={usuarios}
                                                value={usuario}
                                                onChange={(event, newValue) => {
                                                    setUsuario(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Usuario" required />}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={perfils}
                                                value={perfil}
                                                onChange={(event, newValue) => {
                                                    setPerfil(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Perfil" required />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default UsuarioPerfil;
