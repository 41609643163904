import React from 'react';
import axios from "axios";
import ProjectItem from "../ProjectList/ProjectItem";
import {Typography,Pagination} from "@mui/material";
import {API_URL,ROWS_OF_PAGE} from '../../utils/constants/paths';
import { useNavigate } from "react-router-dom";

const Arboles = () => {
    const [projects, setPost] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    
    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    React.useEffect(() => {
        axios.post(`${API_URL}arbolPatrimonial/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, {headers}).then((response) => {
            setPost(response.data);
            setTotalPages(response.data[0].totalPages);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login"); 
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);
    if (!projects) return null;
    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Árboles</Typography>
            {
                projects.map((project, key) => (
                    <ProjectItem item={project}/>
                ))
            }
            <Pagination
                    color="primary"
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                />
        </React.Fragment>
    );
};

export default Arboles;

