import React from 'react';
import axios from 'axios';
import EvaluacionItem from '../../pages/catalogos/EvaluacionItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const Evaluacion = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [evaluacions, setEvaluacions] = React.useState(null);
    const [arbolPatrimonials, setArbolPatrimonials] = React.useState(false);
    const [arbolPatrimonial, setArbolPatrimonial] = React.useState({});
    const [callArbolPatrimonial, setCallArbolPatrimonial] = React.useState(false);
    const [formas, setFormas] = React.useState(false);
    const [forma, setForma] = React.useState({});
    const [callForma, setCallForma] = React.useState(false);
    const [posicions, setPosicions] = React.useState(false);
    const [posicion, setPosicion] = React.useState({});
    const [callPosicion, setCallPosicion] = React.useState(false);
    const [ultimaPodas, setUltimaPodas] = React.useState(false);
    const [ultimaPoda, setUltimaPoda] = React.useState({});
    const [callUltimaPoda, setCallUltimaPoda] = React.useState(false);
    const [valorEsteticos, setValorEsteticos] = React.useState(false);
    const [valorEstetico, setValorEstetico] = React.useState({});
    const [callValorEstetico, setCallValorEstetico] = React.useState(false);
    const [tipologiaLugars, setTipologiaLugars] = React.useState(false);
    const [tipologiaLugar, setTipologiaLugar] = React.useState({});
    const [callTipologiaLugar, setCallTipologiaLugar] = React.useState(false);
    const [fertilidadSuelos, setFertilidadSuelos] = React.useState(false);
    const [fertilidadSuelo, setFertilidadSuelo] = React.useState({});
    const [callFertilidadSuelo, setCallFertilidadSuelo] = React.useState(false);
    const [suelos, setSuelos] = React.useState(false);
    const [suelo, setSuelo] = React.useState({});
    const [callSuelo, setCallSuelo] = React.useState(false);
    const [irrigacions, setIrrigacions] = React.useState(false);
    const [irrigacion, setIrrigacion] = React.useState({});
    const [callIrrigacion, setCallIrrigacion] = React.useState(false);
    const [dificultadOperativas, setDificultadOperativas] = React.useState(false);
    const [dificultadOperativa, setDificultadOperativa] = React.useState({});
    const [callDificultadOperativa, setCallDificultadOperativa] = React.useState(false);
    const [dificultadOperativaDescripcions, setDificultadOperativaDescripcions] = React.useState(false);
    const [dificultadOperativaDescripcion, setDificultadOperativaDescripcion] = React.useState({});
    const [callDificultadOperativaDescripcion, setCallDificultadOperativaDescripcion] = React.useState(false);
    const [raizBajoPavimentos, setRaizBajoPavimentos] = React.useState(false);
    const [raizBajoPavimento, setRaizBajoPavimento] = React.useState({});
    const [callRaizBajoPavimento, setCallRaizBajoPavimento] = React.useState(false);
    const [colorFollajes, setColorFollajes] = React.useState(false);
    const [colorFollaje, setColorFollaje] = React.useState({});
    const [callColorFollaje, setCallColorFollaje] = React.useState(false);
    const [tamanoHojas, setTamanoHojas] = React.useState(false);
    const [tamanoHoja, setTamanoHoja] = React.useState({});
    const [callTamanoHoja, setCallTamanoHoja] = React.useState(false);
    const [densidadCopas, setDensidadCopas] = React.useState(false);
    const [densidadCopa, setDensidadCopa] = React.useState({});
    const [callDensidadCopa, setCallDensidadCopa] = React.useState(false);
    const [crecimientoAnuals, setCrecimientoAnuals] = React.useState(false);
    const [crecimientoAnual, setCrecimientoAnual] = React.useState({});
    const [callCrecimientoAnual, setCallCrecimientoAnual] = React.useState(false);
    const [crecimientoCallos, setCrecimientoCallos] = React.useState(false);
    const [crecimientoCallo, setCrecimientoCallo] = React.useState({});
    const [callCrecimientoCallo, setCallCrecimientoCallo] = React.useState(false);
    const [inclinacions, setInclinacions] = React.useState(false);
    const [inclinacion, setInclinacion] = React.useState({});
    const [callInclinacion, setCallInclinacion] = React.useState(false);
    const [reduccionAparatoRadiculars, setReduccionAparatoRadiculars] = React.useState(false);
    const [reduccionAparatoRadicular, setReduccionAparatoRadicular] = React.useState({});
    const [callReduccionAparatoRadicular, setCallReduccionAparatoRadicular] = React.useState(false);
    const [patologiaIdentificacions, setPatologiaIdentificacions] = React.useState(false);
    const [patologiaIdentificacion, setPatologiaIdentificacion] = React.useState({});
    const [callPatologiaIdentificacion, setCallPatologiaIdentificacion] = React.useState(false);
    const [patologiaNivelDanos, setPatologiaNivelDanos] = React.useState(false);
    const [patologiaNivelDano, setPatologiaNivelDano] = React.useState({});
    const [callPatologiaNivelDano, setCallPatologiaNivelDano] = React.useState(false);
    const [causaPatologiaIdentificacions, setCausaPatologiaIdentificacions] = React.useState(false);
    const [causaPatologiaIdentificacion, setCausaPatologiaIdentificacion] = React.useState({});
    const [callCausaPatologiaIdentificacion, setCallCausaPatologiaIdentificacion] = React.useState(false);
    const [causaPatologiaNivelAgenteDanos, setCausaPatologiaNivelAgenteDanos] = React.useState(false);
    const [causaPatologiaNivelAgenteDano, setCausaPatologiaNivelAgenteDano] = React.useState({});
    const [callCausaPatologiaNivelAgenteDano, setCallCausaPatologiaNivelAgenteDano] = React.useState(false);
    const [riesgoCaidas, setRiesgoCaidas] = React.useState(false);
    const [riesgoCaida, setRiesgoCaida] = React.useState({});
    const [callRiesgoCaida, setCallRiesgoCaida] = React.useState(false);
    const [susceptibilidadAreas, setSusceptibilidadAreas] = React.useState(false);
    const [susceptibilidadArea, setSusceptibilidadArea] = React.useState({});
    const [callSusceptibilidadArea, setCallSusceptibilidadArea] = React.useState(false);
    const [concurrenciaAreas, setConcurrenciaAreas] = React.useState(false);
    const [concurrenciaArea, setConcurrenciaArea] = React.useState({});
    const [callConcurrenciaArea, setCallConcurrenciaArea] = React.useState(false);
    const [estadoLetreros, setEstadoLetreros] = React.useState(false);
    const [estadoLetrero, setEstadoLetrero] = React.useState({});
    const [callEstadoLetrero, setCallEstadoLetrero] = React.useState(false);
    const [valorEcologicos, setValorEcologicos] = React.useState(false);
    const [valorEcologico, setValorEcologico] = React.useState({});
    const [callValorEcologico, setCallValorEcologico] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [arbolPatrimonialId, setArbolPatrimonialId] = React.useState(null);
    const [evaluador, setEvaluador] = React.useState(null);
    const [fecha, setFecha] = React.useState(null);
    const [dAP, setDAP] = React.useState(null);
    const [circunferencia, setCircunferencia] = React.useState(null);
    const [proyeccionCopa, setProyeccionCopa] = React.useState(null);
    const [altura, setAltura] = React.useState(null);
    const [alturaPrimeraRama, setAlturaPrimeraRama] = React.useState(null);
    const [formaId, setFormaId] = React.useState(null);
    const [posicionId, setPosicionId] = React.useState(null);
    const [ultimaPodaId, setUltimaPodaId] = React.useState(null);
    const [valorEsteticoId, setValorEsteticoId] = React.useState(null);
    const [tipologiaLugarId, setTipologiaLugarId] = React.useState(null);
    const [fertilidadSueloId, setFertilidadSueloId] = React.useState(null);
    const [sueloId, setSueloId] = React.useState(null);
    const [irrigacionId, setIrrigacionId] = React.useState(null);
    const [dificultadOperativaId, setDificultadOperativaId] = React.useState(null);
    const [dificultadOperativaDescripcionId, setDificultadOperativaDescripcionId] = React.useState(null);
    const [raizBajoPavimentoId, setRaizBajoPavimentoId] = React.useState(null);
    const [colorFollajeId, setColorFollajeId] = React.useState(null);
    const [tamanoHojaId, setTamanoHojaId] = React.useState(null);
    const [densidadCopaId, setDensidadCopaId] = React.useState(null);
    const [crecimientoAnualId, setCrecimientoAnualId] = React.useState(null);
    const [crecimientoCalloId, setCrecimientoCalloId] = React.useState(null);
    const [inclinacionId, setInclinacionId] = React.useState(null);
    const [reduccionAparatoRadicularId, setReduccionAparatoRadicularId] = React.useState(null);
    const [troncos, setTroncos] = React.useState(null);
    const [patologiaIdentificacionId, setPatologiaIdentificacionId] = React.useState(null);
    const [patologiaNivelDanoId, setPatologiaNivelDanoId] = React.useState(null);
    const [causaPatologiaIdentificacionId, setCausaPatologiaIdentificacionId] = React.useState(null);
    const [causaPatologiaNivelAgenteDanoId, setCausaPatologiaNivelAgenteDanoId] = React.useState(null);
    const [riesgoCaidaId, setRiesgoCaidaId] = React.useState(null);
    const [susceptibilidadAreaId, setSusceptibilidadAreaId] = React.useState(null);
    const [concurrenciaAreaId, setConcurrenciaAreaId] = React.useState(null);
    const [estadoLetreroId, setEstadoLetreroId] = React.useState(null);
    const [estadoLetreroObservaciones, setEstadoLetreroObservaciones] = React.useState(null);
    const [valorEcologicoId, setValorEcologicoId] = React.useState(null);

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
            setArbolPatrimonial(null);
            setForma(null);
            setPosicion(null);
            setUltimaPoda(null);
            setValorEstetico(null);
            setTipologiaLugar(null);
            setFertilidadSuelo(null);
            setSuelo(null);
            setIrrigacion(null);
            setDificultadOperativa(null);
            setDificultadOperativaDescripcion(null);
            setRaizBajoPavimento(null);
            setColorFollaje(null);
            setTamanoHoja(null);
            setDensidadCopa(null);
            setCrecimientoAnual(null);
            setCrecimientoCallo(null);
            setInclinacion(null);
            setReduccionAparatoRadicular(null);
            setPatologiaIdentificacion(null);
            setPatologiaNivelDano(null);
            setCausaPatologiaIdentificacion(null);
            setCausaPatologiaNivelAgenteDano(null);
            setRiesgoCaida(null);
            setSusceptibilidadArea(null);
            setConcurrenciaArea(null);
            setEstadoLetrero(null);
            setValorEcologico(null);
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setArbolPatrimonialId(refItem.arbolPatrimonialId);
            setEvaluador(refItem.evaluador);
            setFecha(refItem.fecha);
            setDAP(refItem.dAP);
            setCircunferencia(refItem.circunferencia);
            setProyeccionCopa(refItem.proyeccionCopa);
            setAltura(refItem.altura);
            setAlturaPrimeraRama(refItem.alturaPrimeraRama);
            setFormaId(refItem.formaId);
            setPosicionId(refItem.posicionId);
            setUltimaPodaId(refItem.ultimaPodaId);
            setValorEsteticoId(refItem.valorEsteticoId);
            setTipologiaLugarId(refItem.tipologiaLugarId);
            setFertilidadSueloId(refItem.fertilidadSueloId);
            setSueloId(refItem.sueloId);
            setIrrigacionId(refItem.irrigacionId);
            setDificultadOperativaId(refItem.dificultadOperativaId);
            setDificultadOperativaDescripcionId(refItem.dificultadOperativaDescripcionId);
            setRaizBajoPavimentoId(refItem.raizBajoPavimentoId);
            setColorFollajeId(refItem.colorFollajeId);
            setTamanoHojaId(refItem.tamanoHojaId);
            setDensidadCopaId(refItem.densidadCopaId);
            setCrecimientoAnualId(refItem.crecimientoAnualId);
            setCrecimientoCalloId(refItem.crecimientoCalloId);
            setInclinacionId(refItem.inclinacionId);
            setReduccionAparatoRadicularId(refItem.reduccionAparatoRadicularId);
            setTroncos(refItem.troncos);
            setPatologiaIdentificacionId(refItem.patologiaIdentificacionId);
            setPatologiaNivelDanoId(refItem.patologiaNivelDanoId);
            setCausaPatologiaIdentificacionId(refItem.causaPatologiaIdentificacionId);
            setCausaPatologiaNivelAgenteDanoId(refItem.causaPatologiaNivelAgenteDanoId);
            setRiesgoCaidaId(refItem.riesgoCaidaId);
            setSusceptibilidadAreaId(refItem.susceptibilidadAreaId);
            setConcurrenciaAreaId(refItem.concurrenciaAreaId);
            setEstadoLetreroId(refItem.estadoLetreroId);
            setEstadoLetreroObservaciones(refItem.estadoLetreroObservaciones);
            setValorEcologicoId(refItem.valorEcologicoId);
        }
    }, [open]);

    React.useEffect(() => {
        if (!arbolPatrimonials || !arbolPatrimonialId) {
            setArbolPatrimonial(null);
            return;
        }
        setArbolPatrimonial(arbolPatrimonials.find((e) => { return e.arbolPatrimonialId === arbolPatrimonialId }));
    }, [arbolPatrimonialId]);

    React.useEffect(() => {
        if (!formas || !formaId) {
            setForma(null);
            return;
        }
        setForma(formas.find((e) => { return e.formaId === formaId }));
    }, [formaId]);

    React.useEffect(() => {
        if (!posicions || !posicionId) {
            setPosicion(null);
            return;
        }
        setPosicion(posicions.find((e) => { return e.posicionId === posicionId }));
    }, [posicionId]);

    React.useEffect(() => {
        if (!ultimaPodas || !ultimaPodaId) {
            setUltimaPoda(null);
            return;
        }
        setUltimaPoda(ultimaPodas.find((e) => { return e.ultimaPodaId === ultimaPodaId }));
    }, [ultimaPodaId]);

    React.useEffect(() => {
        if (!valorEsteticos || !valorEsteticoId) {
            setValorEstetico(null);
            return;
        }
        setValorEstetico(valorEsteticos.find((e) => { return e.valorEsteticoId === valorEsteticoId }));
    }, [valorEsteticoId]);

    React.useEffect(() => {
        if (!tipologiaLugars || !tipologiaLugarId) {
            setTipologiaLugar(null);
            return;
        }
        setTipologiaLugar(tipologiaLugars.find((e) => { return e.tipologiaLugarId === tipologiaLugarId }));
    }, [tipologiaLugarId]);

    React.useEffect(() => {
        if (!fertilidadSuelos || !fertilidadSueloId) {
            setFertilidadSuelo(null);
            return;
        }
        setFertilidadSuelo(fertilidadSuelos.find((e) => { return e.fertilidadSueloId === fertilidadSueloId }));
    }, [fertilidadSueloId]);

    React.useEffect(() => {
        if (!suelos || !sueloId) {
            setSuelo(null);
            return;
        }
        setSuelo(suelos.find((e) => { return e.sueloId === sueloId }));
    }, [sueloId]);

    React.useEffect(() => {
        if (!irrigacions || !irrigacionId) {
            setIrrigacion(null);
            return;
        }
        setIrrigacion(irrigacions.find((e) => { return e.irrigacionId === irrigacionId }));
    }, [irrigacionId]);

    React.useEffect(() => {
        if (!dificultadOperativas || !dificultadOperativaId) {
            setDificultadOperativa(null);
            return;
        }
        setDificultadOperativa(dificultadOperativas.find((e) => { return e.dificultadOperativaId === dificultadOperativaId }));
    }, [dificultadOperativaId]);

    React.useEffect(() => {
        if (!dificultadOperativaDescripcions || !dificultadOperativaDescripcionId) {
            setDificultadOperativaDescripcion(null);
            return;
        }
        setDificultadOperativaDescripcion(dificultadOperativaDescripcions.find((e) => { return e.dificultadOperativaDescripcionId === dificultadOperativaDescripcionId }));
    }, [dificultadOperativaDescripcionId]);

    React.useEffect(() => {
        if (!raizBajoPavimentos || !raizBajoPavimentoId) {
            setRaizBajoPavimento(null);
            return;
        }
        setRaizBajoPavimento(raizBajoPavimentos.find((e) => { return e.raizBajoPavimentoId === raizBajoPavimentoId }));
    }, [raizBajoPavimentoId]);

    React.useEffect(() => {
        if (!colorFollajes || !colorFollajeId) {
            setColorFollaje(null);
            return;
        }
        setColorFollaje(colorFollajes.find((e) => { return e.colorFollajeId === colorFollajeId }));
    }, [colorFollajeId]);

    React.useEffect(() => {
        if (!tamanoHojas || !tamanoHojaId) {
            setTamanoHoja(null);
            return;
        }
        setTamanoHoja(tamanoHojas.find((e) => { return e.tamanoHojaId === tamanoHojaId }));
    }, [tamanoHojaId]);

    React.useEffect(() => {
        if (!densidadCopas || !densidadCopaId) {
            setDensidadCopa(null);
            return;
        }
        setDensidadCopa(densidadCopas.find((e) => { return e.densidadCopaId === densidadCopaId }));
    }, [densidadCopaId]);

    React.useEffect(() => {
        if (!crecimientoAnuals || !crecimientoAnualId) {
            setCrecimientoAnual(null);
            return;
        }
        setCrecimientoAnual(crecimientoAnuals.find((e) => { return e.crecimientoAnualId === crecimientoAnualId }));
    }, [crecimientoAnualId]);

    React.useEffect(() => {
        if (!crecimientoCallos || !crecimientoCalloId) {
            setCrecimientoCallo(null);
            return;
        }
        setCrecimientoCallo(crecimientoCallos.find((e) => { return e.crecimientoCalloId === crecimientoCalloId }));
    }, [crecimientoCalloId]);

    React.useEffect(() => {
        if (!inclinacions || !inclinacionId) {
            setInclinacion(null);
            return;
        }
        setInclinacion(inclinacions.find((e) => { return e.inclinacionId === inclinacionId }));
    }, [inclinacionId]);

    React.useEffect(() => {
        if (!reduccionAparatoRadiculars || !reduccionAparatoRadicularId) {
            setReduccionAparatoRadicular(null);
            return;
        }
        setReduccionAparatoRadicular(reduccionAparatoRadiculars.find((e) => { return e.reduccionAparatoRadicularId === reduccionAparatoRadicularId }));
    }, [reduccionAparatoRadicularId]);

    React.useEffect(() => {
        if (!patologiaIdentificacions || !patologiaIdentificacionId) {
            setPatologiaIdentificacion(null);
            return;
        }
        setPatologiaIdentificacion(patologiaIdentificacions.find((e) => { return e.patologiaIdentificacionId === patologiaIdentificacionId }));
    }, [patologiaIdentificacionId]);

    React.useEffect(() => {
        if (!patologiaNivelDanos || !patologiaNivelDanoId) {
            setPatologiaNivelDano(null);
            return;
        }
        setPatologiaNivelDano(patologiaNivelDanos.find((e) => { return e.patologiaNivelDanoId === patologiaNivelDanoId }));
    }, [patologiaNivelDanoId]);

    React.useEffect(() => {
        if (!causaPatologiaIdentificacions || !causaPatologiaIdentificacionId) {
            setCausaPatologiaIdentificacion(null);
            return;
        }
        setCausaPatologiaIdentificacion(causaPatologiaIdentificacions.find((e) => { return e.causaPatologiaIdentificacionId === causaPatologiaIdentificacionId }));
    }, [causaPatologiaIdentificacionId]);

    React.useEffect(() => {
        if (!causaPatologiaNivelAgenteDanos || !causaPatologiaNivelAgenteDanoId) {
            setCausaPatologiaNivelAgenteDano(null);
            return;
        }
        setCausaPatologiaNivelAgenteDano(causaPatologiaNivelAgenteDanos.find((e) => { return e.causaPatologiaNivelAgenteDanoId === causaPatologiaNivelAgenteDanoId }));
    }, [causaPatologiaNivelAgenteDanoId]);

    React.useEffect(() => {
        if (!riesgoCaidas || !riesgoCaidaId) {
            setRiesgoCaida(null);
            return;
        }
        setRiesgoCaida(riesgoCaidas.find((e) => { return e.riesgoCaidaId === riesgoCaidaId }));
    }, [riesgoCaidaId]);

    React.useEffect(() => {
        if (!susceptibilidadAreas || !susceptibilidadAreaId) {
            setSusceptibilidadArea(null);
            return;
        }
        setSusceptibilidadArea(susceptibilidadAreas.find((e) => { return e.susceptibilidadAreaId === susceptibilidadAreaId }));
    }, [susceptibilidadAreaId]);

    React.useEffect(() => {
        if (!concurrenciaAreas || !concurrenciaAreaId) {
            setConcurrenciaArea(null);
            return;
        }
        setConcurrenciaArea(concurrenciaAreas.find((e) => { return e.concurrenciaAreaId === concurrenciaAreaId }));
    }, [concurrenciaAreaId]);

    React.useEffect(() => {
        if (!estadoLetreros || !estadoLetreroId) {
            setEstadoLetrero(null);
            return;
        }
        setEstadoLetrero(estadoLetreros.find((e) => { return e.estadoLetreroId === estadoLetreroId }));
    }, [estadoLetreroId]);

    React.useEffect(() => {
        if (!valorEcologicos || !valorEcologicoId) {
            setValorEcologico(null);
            return;
        }
        setValorEcologico(valorEcologicos.find((e) => { return e.valorEcologicoId === valorEcologicoId }));
    }, [valorEcologicoId]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.arbolPatrimonialId = arbolPatrimonial.arbolPatrimonialId;
        newItem.evaluador = evaluador;
        newItem.fecha = fecha;
        newItem.dAP = dAP;
        newItem.circunferencia = circunferencia;
        newItem.proyeccionCopa = proyeccionCopa;
        newItem.altura = altura;
        newItem.alturaPrimeraRama = alturaPrimeraRama;
        newItem.formaId = forma == null ? null : forma.formaId;
        newItem.posicionId = posicion == null ? null : posicion.posicionId;
        newItem.ultimaPodaId = ultimaPoda == null ? null : ultimaPoda.ultimaPodaId;
        newItem.valorEsteticoId = valorEstetico == null ? null : valorEstetico.valorEsteticoId;
        newItem.tipologiaLugarId = tipologiaLugar == null ? null : tipologiaLugar.tipologiaLugarId;
        newItem.fertilidadSueloId = fertilidadSuelo == null ? null : fertilidadSuelo.fertilidadSueloId;
        newItem.sueloId = suelo == null ? null : suelo.sueloId;
        newItem.irrigacionId = irrigacion == null ? null : irrigacion.irrigacionId;
        newItem.dificultadOperativaId = dificultadOperativa == null ? null : dificultadOperativa.dificultadOperativaId;
        newItem.dificultadOperativaDescripcionId = dificultadOperativaDescripcion == null ? null : dificultadOperativaDescripcion.dificultadOperativaDescripcionId;
        newItem.raizBajoPavimentoId = raizBajoPavimento == null ? null : raizBajoPavimento.raizBajoPavimentoId;
        newItem.colorFollajeId = colorFollaje == null ? null : colorFollaje.colorFollajeId;
        newItem.tamanoHojaId = tamanoHoja == null ? null : tamanoHoja.tamanoHojaId;
        newItem.densidadCopaId = densidadCopa == null ? null : densidadCopa.densidadCopaId;
        newItem.crecimientoAnualId = crecimientoAnual == null ? null : crecimientoAnual.crecimientoAnualId;
        newItem.crecimientoCalloId = crecimientoCallo == null ? null : crecimientoCallo.crecimientoCalloId;
        newItem.inclinacionId = inclinacion == null ? null : inclinacion.inclinacionId;
        newItem.reduccionAparatoRadicularId = reduccionAparatoRadicular == null ? null : reduccionAparatoRadicular.reduccionAparatoRadicularId;
        newItem.troncos = troncos;
        newItem.patologiaIdentificacionId = patologiaIdentificacion == null ? null : patologiaIdentificacion.patologiaIdentificacionId;
        newItem.patologiaNivelDanoId = patologiaNivelDano == null ? null : patologiaNivelDano.patologiaNivelDanoId;
        newItem.causaPatologiaIdentificacionId = causaPatologiaIdentificacion == null ? null : causaPatologiaIdentificacion.causaPatologiaIdentificacionId;
        newItem.causaPatologiaNivelAgenteDanoId = causaPatologiaNivelAgenteDano == null ? null : causaPatologiaNivelAgenteDano.causaPatologiaNivelAgenteDanoId;
        newItem.riesgoCaidaId = riesgoCaida == null ? null : riesgoCaida.riesgoCaidaId;
        newItem.susceptibilidadAreaId = susceptibilidadArea == null ? null : susceptibilidadArea.susceptibilidadAreaId;
        newItem.concurrenciaAreaId = concurrenciaArea == null ? null : concurrenciaArea.concurrenciaAreaId;
        newItem.estadoLetreroId = estadoLetrero == null ? null : estadoLetrero.estadoLetreroId;
        newItem.estadoLetreroObservaciones = estadoLetreroObservaciones;
        newItem.valorEcologicoId = valorEcologico == null ? null : valorEcologico.valorEcologicoId;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.evaluacionId = 0;
            axios.post(`${API_URL}evaluacion/create`, newItem, { headers }).then((response) => {
                setEvaluacions(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}evaluacion/update`, newItem, { headers }).then((response) => {
                setEvaluacions(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}evaluacion/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, { headers }).then((response) => {
            setEvaluacions(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallArbolPatrimonial(true);
            setCallForma(true);
            setCallPosicion(true);
            setCallUltimaPoda(true);
            setCallValorEstetico(true);
            setCallTipologiaLugar(true);
            setCallFertilidadSuelo(true);
            setCallSuelo(true);
            setCallIrrigacion(true);
            setCallDificultadOperativa(true);
            setCallDificultadOperativaDescripcion(true);
            setCallRaizBajoPavimento(true);
            setCallColorFollaje(true);
            setCallTamanoHoja(true);
            setCallDensidadCopa(true);
            setCallCrecimientoAnual(true);
            setCallCrecimientoCallo(true);
            setCallInclinacion(true);
            setCallReduccionAparatoRadicular(true);
            setCallPatologiaIdentificacion(true);
            setCallPatologiaNivelDano(true);
            setCallCausaPatologiaIdentificacion(true);
            setCallCausaPatologiaNivelAgenteDano(true);
            setCallRiesgoCaida(true);
            setCallSusceptibilidadArea(true);
            setCallConcurrenciaArea(true);
            setCallEstadoLetrero(true);
            setCallValorEcologico(true);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    React.useEffect(() => {
        if (!callArbolPatrimonial)
            return;
        axios.post(`${API_URL}arbolPatrimonial/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setArbolPatrimonials(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callArbolPatrimonial]);

    React.useEffect(() => {
        if (!callForma)
            return;
        axios.post(`${API_URL}forma/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setFormas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callForma]);

    React.useEffect(() => {
        if (!callPosicion)
            return;
        axios.post(`${API_URL}posicion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setPosicions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callPosicion]);

    React.useEffect(() => {
        if (!callUltimaPoda)
            return;
        axios.post(`${API_URL}ultimaPoda/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setUltimaPodas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callUltimaPoda]);

    React.useEffect(() => {
        if (!callValorEstetico)
            return;
        axios.post(`${API_URL}valorEstetico/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setValorEsteticos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callValorEstetico]);

    React.useEffect(() => {
        if (!callTipologiaLugar)
            return;
        axios.post(`${API_URL}tipologiaLugar/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setTipologiaLugars(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callTipologiaLugar]);

    React.useEffect(() => {
        if (!callFertilidadSuelo)
            return;
        axios.post(`${API_URL}fertilidadSuelo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setFertilidadSuelos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callFertilidadSuelo]);

    React.useEffect(() => {
        if (!callSuelo)
            return;
        axios.post(`${API_URL}suelo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setSuelos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callSuelo]);

    React.useEffect(() => {
        if (!callIrrigacion)
            return;
        axios.post(`${API_URL}irrigacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setIrrigacions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callIrrigacion]);

    React.useEffect(() => {
        if (!callDificultadOperativa)
            return;
        axios.post(`${API_URL}dificultadOperativa/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setDificultadOperativas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callDificultadOperativa]);

    React.useEffect(() => {
        if (!callDificultadOperativaDescripcion)
            return;
        axios.post(`${API_URL}dificultadOperativaDescripcion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setDificultadOperativaDescripcions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callDificultadOperativaDescripcion]);

    React.useEffect(() => {
        if (!callRaizBajoPavimento)
            return;
        axios.post(`${API_URL}raizBajoPavimento/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setRaizBajoPavimentos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callRaizBajoPavimento]);

    React.useEffect(() => {
        if (!callColorFollaje)
            return;
        axios.post(`${API_URL}colorFollaje/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setColorFollajes(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callColorFollaje]);

    React.useEffect(() => {
        if (!callTamanoHoja)
            return;
        axios.post(`${API_URL}tamanoHoja/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setTamanoHojas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callTamanoHoja]);

    React.useEffect(() => {
        if (!callDensidadCopa)
            return;
        axios.post(`${API_URL}densidadCopa/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setDensidadCopas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callDensidadCopa]);

    React.useEffect(() => {
        if (!callCrecimientoAnual)
            return;
        axios.post(`${API_URL}crecimientoAnual/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setCrecimientoAnuals(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callCrecimientoAnual]);

    React.useEffect(() => {
        if (!callCrecimientoCallo)
            return;
        axios.post(`${API_URL}crecimientoCallo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setCrecimientoCallos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callCrecimientoCallo]);

    React.useEffect(() => {
        if (!callInclinacion)
            return;
        axios.post(`${API_URL}inclinacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setInclinacions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callInclinacion]);

    React.useEffect(() => {
        if (!callReduccionAparatoRadicular)
            return;
        axios.post(`${API_URL}reduccionAparatoRadicular/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setReduccionAparatoRadiculars(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callReduccionAparatoRadicular]);

    React.useEffect(() => {
        if (!callPatologiaIdentificacion)
            return;
        axios.post(`${API_URL}patologiaIdentificacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setPatologiaIdentificacions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callPatologiaIdentificacion]);

    React.useEffect(() => {
        if (!callPatologiaNivelDano)
            return;
        axios.post(`${API_URL}patologiaNivelDano/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setPatologiaNivelDanos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callPatologiaNivelDano]);

    React.useEffect(() => {
        if (!callCausaPatologiaIdentificacion)
            return;
        axios.post(`${API_URL}causaPatologiaIdentificacion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setCausaPatologiaIdentificacions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callCausaPatologiaIdentificacion]);

    React.useEffect(() => {
        if (!callCausaPatologiaNivelAgenteDano)
            return;
        axios.post(`${API_URL}causaPatologiaNivelAgenteDano/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setCausaPatologiaNivelAgenteDanos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callCausaPatologiaNivelAgenteDano]);

    React.useEffect(() => {
        if (!callRiesgoCaida)
            return;
        axios.post(`${API_URL}riesgoCaida/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setRiesgoCaidas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callRiesgoCaida]);

    React.useEffect(() => {
        if (!callSusceptibilidadArea)
            return;
        axios.post(`${API_URL}susceptibilidadArea/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setSusceptibilidadAreas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callSusceptibilidadArea]);

    React.useEffect(() => {
        if (!callConcurrenciaArea)
            return;
        axios.post(`${API_URL}concurrenciaArea/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setConcurrenciaAreas(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callConcurrenciaArea]);

    React.useEffect(() => {
        if (!callEstadoLetrero)
            return;
        axios.post(`${API_URL}estadoLetrero/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setEstadoLetreros(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callEstadoLetrero]);

    React.useEffect(() => {
        if (!callValorEcologico)
            return;
        axios.post(`${API_URL}valorEcologico/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setValorEcologicos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callValorEcologico]);

    if (!evaluacions) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>Evaluación</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>}
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        evaluacions.map((evaluacion, key) => (<EvaluacionItem item={evaluacion} setItems={setEvaluacions} key={evaluacion.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.codigo}
                                                options={arbolPatrimonials}
                                                value={arbolPatrimonial}
                                                onChange={(event, newValue) => {
                                                    setArbolPatrimonial(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ArbolPatrimonial" required />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Evaluador' defaultValue={refItem.evaluador} inputProps={{ maxLength: 100 }} onChange={(event) => { setEvaluador(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Fecha' defaultValue={refItem.fecha} inputProps={{ maxLength: 23 }} onChange={(event) => { setFecha(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='DAP' defaultValue={refItem.dAP} inputProps={{ maxLength: 10 }} onChange={(event) => { setDAP(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Circunferencia' defaultValue={refItem.circunferencia} inputProps={{ maxLength: 10 }} onChange={(event) => { setCircunferencia(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='ProyeccionCopa' defaultValue={refItem.proyeccionCopa} inputProps={{ maxLength: 10 }} onChange={(event) => { setProyeccionCopa(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Altura' defaultValue={refItem.altura} inputProps={{ maxLength: 10 }} onChange={(event) => { setAltura(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='AlturaPrimeraRama' defaultValue={refItem.alturaPrimeraRama} inputProps={{ maxLength: 10 }} onChange={(event) => { setAlturaPrimeraRama(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={formas}
                                                value={forma}
                                                onChange={(event, newValue) => {
                                                    setForma(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Forma" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={posicions}
                                                value={posicion}
                                                onChange={(event, newValue) => {
                                                    setPosicion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Posicion" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={ultimaPodas}
                                                value={ultimaPoda}
                                                onChange={(event, newValue) => {
                                                    setUltimaPoda(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="UltimaPoda" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={valorEsteticos}
                                                value={valorEstetico}
                                                onChange={(event, newValue) => {
                                                    setValorEstetico(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ValorEstetico" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={tipologiaLugars}
                                                value={tipologiaLugar}
                                                onChange={(event, newValue) => {
                                                    setTipologiaLugar(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="TipologiaLugar" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={fertilidadSuelos}
                                                value={fertilidadSuelo}
                                                onChange={(event, newValue) => {
                                                    setFertilidadSuelo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="FertilidadSuelo" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={suelos}
                                                value={suelo}
                                                onChange={(event, newValue) => {
                                                    setSuelo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Suelo" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={irrigacions}
                                                value={irrigacion}
                                                onChange={(event, newValue) => {
                                                    setIrrigacion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Irrigacion" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={dificultadOperativas}
                                                value={dificultadOperativa}
                                                onChange={(event, newValue) => {
                                                    setDificultadOperativa(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="DificultadOperativa" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={dificultadOperativaDescripcions}
                                                value={dificultadOperativaDescripcion}
                                                onChange={(event, newValue) => {
                                                    setDificultadOperativaDescripcion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="DificultadOperativaDescripcion" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={raizBajoPavimentos}
                                                value={raizBajoPavimento}
                                                onChange={(event, newValue) => {
                                                    setRaizBajoPavimento(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="RaizBajoPavimento" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={colorFollajes}
                                                value={colorFollaje}
                                                onChange={(event, newValue) => {
                                                    setColorFollaje(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ColorFollaje" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={tamanoHojas}
                                                value={tamanoHoja}
                                                onChange={(event, newValue) => {
                                                    setTamanoHoja(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="TamanoHoja" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={densidadCopas}
                                                value={densidadCopa}
                                                onChange={(event, newValue) => {
                                                    setDensidadCopa(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="DensidadCopa" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={crecimientoAnuals}
                                                value={crecimientoAnual}
                                                onChange={(event, newValue) => {
                                                    setCrecimientoAnual(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="CrecimientoAnual" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={crecimientoCallos}
                                                value={crecimientoCallo}
                                                onChange={(event, newValue) => {
                                                    setCrecimientoCallo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="CrecimientoCallo" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={inclinacions}
                                                value={inclinacion}
                                                onChange={(event, newValue) => {
                                                    setInclinacion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Inclinacion" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={reduccionAparatoRadiculars}
                                                value={reduccionAparatoRadicular}
                                                onChange={(event, newValue) => {
                                                    setReduccionAparatoRadicular(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ReduccionAparatoRadicular" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Troncos' defaultValue={refItem.troncos} inputProps={{ maxLength: 5 }} onChange={(event) => { setTroncos(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={patologiaIdentificacions}
                                                value={patologiaIdentificacion}
                                                onChange={(event, newValue) => {
                                                    setPatologiaIdentificacion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="PatologiaIdentificacion" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={patologiaNivelDanos}
                                                value={patologiaNivelDano}
                                                onChange={(event, newValue) => {
                                                    setPatologiaNivelDano(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="PatologiaNivelDano" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={causaPatologiaIdentificacions}
                                                value={causaPatologiaIdentificacion}
                                                onChange={(event, newValue) => {
                                                    setCausaPatologiaIdentificacion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="CausaPatologiaIdentificacion" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={causaPatologiaNivelAgenteDanos}
                                                value={causaPatologiaNivelAgenteDano}
                                                onChange={(event, newValue) => {
                                                    setCausaPatologiaNivelAgenteDano(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="CausaPatologiaNivelAgenteDano" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={riesgoCaidas}
                                                value={riesgoCaida}
                                                onChange={(event, newValue) => {
                                                    setRiesgoCaida(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="RiesgoCaida" />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={susceptibilidadAreas}
                                                value={susceptibilidadArea}
                                                onChange={(event, newValue) => {
                                                    setSusceptibilidadArea(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="SusceptibilidadArea" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} >
                                        <Grid item xs={6} md={6} lg={6} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={concurrenciaAreas}
                                                value={concurrenciaArea}
                                                onChange={(event, newValue) => {
                                                    setConcurrenciaArea(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ConcurrenciaArea" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={estadoLetreros}
                                                value={estadoLetrero}
                                                onChange={(event, newValue) => {
                                                    setEstadoLetrero(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="EstadoLetrero" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <TextField fullWidth id='outlined' label='EstadoLetreroObservaciones' defaultValue={refItem.estadoLetreroObservaciones} inputProps={{ maxLength: 3000 }} onChange={(event) => { setEstadoLetreroObservaciones(event.target.value); }} />
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={valorEcologicos}
                                                value={valorEcologico}
                                                onChange={(event, newValue) => {
                                                    setValorEcologico(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ValorEcologico" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default Evaluacion;
