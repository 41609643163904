import React from 'react';
import {Grid} from "@mui/material";
import MarkerClusterer from "../maps/MarkerClusterer";
const Mapa = () => {
    return (
        <Grid container spacing={3.75}>
            {<Grid item xs={12}>
                <MarkerClusterer isExample={false}/>
            </Grid>}
        </Grid>
    );
};

export default Mapa;
