import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};
authServices.getCurrentUser = async () => {
    return sessionStorage.getItem('name');
};

authServices.signIn = async (loginCreds) => {
    const { data } = await jwtAuthAxios.post('/auth', loginCreds);
    sessionStorage.setItem('userId', data.userId);
    sessionStorage.setItem('name', data.name);
    sessionStorage.setItem('email', data.email);
    sessionStorage.setItem('perfil', data.perfil);
    return data;
};

export default authServices;