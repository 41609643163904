import React from 'react';
import axios from 'axios';
import IntervencionPlanManejoItem from '../../pages/catalogos/IntervencionPlanManejoItem';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Pagination, Grid, Stack, Fab, Button, Dialog, DialogActions, DialogContent, TextField, useTheme, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { API_URL, ROWS_OF_PAGE } from '../../utils/constants/paths';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from "react-router-dom";

const IntervencionPlanManejo = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [intervencionPlanManejos, setIntervencionPlanManejos] = React.useState(null);
    const [intervencions, setIntervencions] = React.useState(false);
    const [intervencion, setIntervencion] = React.useState({});
    const [callIntervencion, setCallIntervencion] = React.useState(false);
    const [planManejos, setPlanManejos] = React.useState(false);
    const [planManejo, setPlanManejo] = React.useState({});
    const [callPlanManejo, setCallPlanManejo] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(true);
    const [createItem, setCreateItem] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogButtonText, setDialogButtonText] = React.useState('');
    const [refItem, setRefItem] = React.useState({});
    const [intervencionId, setIntervencionId] = React.useState(null);
    const [planManejoId, setPlanManejoId] = React.useState(null);
    const [nota, setNota] = React.useState(null);

    const navigate = useNavigate();
    if (!localStorage.getItem('token'))
        navigate("/user/login");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const commandAlert = (variant, keyWord, customText) => {
        if (customText == undefined)
            customText = `Se ${keyWord} el registro con exito!`;

        const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 2800, timerProgressBar: true, onOpen: toast => { toast.addEventListener('mouseenter', Swal.stopTimer); toast.addEventListener('mouseleave', Swal.resumeTimer); } });
        Toast.fire({ icon: variant, title: customText, background: theme.palette.background.paper });
    };

    React.useEffect(() => {
        if (!open)
            return;
        if (createMode) {
            setDialogTitle('Crear');
            setDialogButtonText('Insertar');
            setIntervencion(null);
            setPlanManejo(null);
        }
        else {
            setDialogTitle('Editar');
            setDialogButtonText('Actualizar');
            setIntervencionId(refItem.intervencionId);
            setPlanManejoId(refItem.planManejoId);
            setNota(refItem.nota);
        }
    }, [open]);

    React.useEffect(() => {
        if (!intervencions || !intervencionId) {
            setIntervencion(null);
            return;
        }
        setIntervencion(intervencions.find((e) => { return e.intervencionId === intervencionId }));
    }, [intervencionId]);

    React.useEffect(() => {
        if (!planManejos || !planManejoId) {
            setPlanManejo(null);
            return;
        }
        setPlanManejo(planManejos.find((e) => { return e.planManejoId === planManejoId }));
    }, [planManejoId]);

    React.useEffect(() => {
        if (!createItem)
            return;
        const newItem = createMode ? {} : refItem;
        newItem.intervencionId = intervencion.intervencionId;
        newItem.planManejoId = planManejo.planManejoId;
        newItem.nota = nota;
        newItem.rowsOfPage = ROWS_OF_PAGE;
        newItem.pageNumber = currentPage;
        if (createMode) {
            newItem.intervencionPlanManejoId = 0;
            axios.post(`${API_URL}intervencionPlanManejo/create`, newItem, { headers }).then((response) => {
                setIntervencionPlanManejos(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'creó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        else {
            axios.post(`${API_URL}intervencionPlanManejo/update`, newItem, { headers }).then((response) => {
                setIntervencionPlanManejos(response.data);
                setTotalPages(response.data[0].totalPages);
                commandAlert('success', 'actualizó', null);
            }).catch((err) => {
                if (err.response === undefined) navigate("/user/login");
                console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
                console.log(err.response);
                console.log(err.response.data);
            });
        }
        setCreateItem(false);
    }, [createItem]);

    React.useEffect(() => {
        if (currentPage == undefined || currentPage === 0)
            return;
        axios.post(`${API_URL}intervencionPlanManejo/getPaginated`, { pageNumber: currentPage, rowsOfPage: ROWS_OF_PAGE }, { headers }).then((response) => {
            setIntervencionPlanManejos(response.data);
            setTotalPages(response.data[0].totalPages);
            setCallIntervencion(true);
            setCallPlanManejo(true);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [currentPage]);

    React.useEffect(() => {
        if (!callIntervencion)
            return;
        axios.post(`${API_URL}intervencion/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setIntervencions(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callIntervencion]);

    React.useEffect(() => {
        if (!callPlanManejo)
            return;
        axios.post(`${API_URL}planManejo/getPaginated`, { pageNumber: 1, rowsOfPage: 9999 }, { headers }).then((response) => {
            setPlanManejos(response.data);
        }).catch((err) => {
            if (err.response === undefined) navigate("/user/login");
            console.log("Se produjo un error en el Sistema de Registro y Gestión de Árboles patrimoniales:");
            console.log(err.response);
            console.log(err.response.data);
        });
    }, [callPlanManejo]);

    if (!intervencionPlanManejos) return null;
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={3}>
                <Typography variant={'h2'} mb={3}>IntervencionPlanManejo</Typography>
                {sessionStorage.getItem("perfil") !== "Lectura" && <Fab size='small' onClick={() => { setOpen(true); setCreateMode(true); setRefItem({}); }} color={'primary'}><AddCircleIcon /></Fab>}
            </Stack>
            <Stack spacing={2}>
                <JumboCardQuick
                    title={
                        <Grid container spacing={1}>
                            <Grid item xs={3} md={3} lg={3}>
                                IntervencionId
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                Nombre
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                Nota
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                Fecha mod.
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                Usuario
                            </Grid>
                        </Grid>
                    }
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }} wrapperSx={{ p: 1 }}>
                    {
                        intervencionPlanManejos.map((intervencionPlanManejo, key) => (<IntervencionPlanManejoItem item={intervencionPlanManejo} setItems={setIntervencionPlanManejos} key={intervencionPlanManejo.Id} currentPage={currentPage} setTotalPages={setTotalPages} commandAlert={commandAlert} setOpen={setOpen} setRefItem={setRefItem} setCreateMode={setCreateMode} />))
                    }
                </JumboCardQuick>
                <Pagination color='primary' count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
            </Stack>
            <Div>
                <Dialog fullWidth='true' maxWidth='xl' open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <JumboCardQuick title={dialogTitle}
                            wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
                            <Box p={2}>
                                <form ref={formRef}>
                                    <Grid container spacing={3} p={1} pb={3}>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.intervencionId}
                                                options={intervencions}
                                                value={intervencion}
                                                onChange={(event, newValue) => {
                                                    setIntervencion(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Intervencion" required />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <Autocomplete
                                                required
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(o) => o.nombre}
                                                options={planManejos}
                                                value={planManejo}
                                                onChange={(event, newValue) => {
                                                    setPlanManejo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="PlanManejo" required />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} >
                                            <TextField fullWidth id='outlined' label='Nota' defaultValue={refItem.nota} inputProps={{ maxLength: 3000 }} onChange={(event) => { setNota(event.target.value); }} />
                                        </Grid>
                                    </Grid>
                                    <Button variant='contained' endIcon={<SendIcon />} onClick={() => {
                                        if (!formRef.current.reportValidity())
                                            return;
                                        setCreateItem(true);
                                        setOpen(false);
                                    }} >
                                        {dialogButtonText}
                                    </Button>
                                </form>
                            </Box>
                        </JumboCardQuick>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false); }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Div>
        </React.Fragment>
    );
};
export default IntervencionPlanManejo;
